import request from "../request";
import axios from "axios"
class Situation {

   /**
   * 修改警情
   * @param {*} data
   * @returns
   */
    static updateJingQingInfoById(data) {
      return request.put("/hawksystemserver/jingqing/updateJingQingInfoById", data);
    }
// 预警记录
  /**
   * 修改用户和任务关联的方式
   * @param {*} params
   * @returns
   */
   static device_waringUpdate(data) {
    return request.put(
      "/hawksystemserver/device_waring/update",
      data
    );
  }
  /**
   * 修改备注
   * @param {*} params
   * @returns
   */
   static updateInfo(data) {
    return request.put(
      "/hawksystemserver/jingqing/updateInfo",
      data
    );
  }

  /**
   * 预警确认
   * @param {*} params
   * @returns
   */
   static sendNotice(data) {
    return request.post(
      "/hawksystemserver/device_waring/sendNotice",
      data
    );
  }

  // SITUATION
  /**
   * 修改用户和任务关联的方式
   * @param {*} params
   * @returns
   */
   static updateDispatchResponseLog(data) {
    return request.put(
      "/hawksystemserver/jingqing/updateDispatchResponseLog",
      data
    );
  }
  /**
   * 警情派发-新版
   * @param {*} params
   * @returns
   */
   static dispatchPoliceTask(params) {
    return request.post("/hawksystemserver/jingqing/dispatchPoliceTask", params);
  }


   /**
   * 警情派发-航线规划
   * @param {*} params
   * @returns
   */
    static savePoliceTaskFlightLine(params) {
      return request.post("/hawksystemserver/jingqing/savePoliceTaskFlightLine", params);
    }
  

  /**
   * 重大警情列表
   * @param {*} data
   * @returns
   */
  static ListMajor(data) {
    return request.get("/hawksystemserver/web-task/majorAlarm110Obj", {
      params: data,
    });
  }

  /**
   * 机构层级警情事件列表
   * @param {*} data
   * @returns
   */
  static ListLevel(data) {
    return request.get("/hawksystemserver/jingqing/organJingQingList", {
      params: data,
    });
  }

  /**
   * 警情数量统计
   * 1、呼入协飞量
   * 2、呼入协飞警情
   * @param {*} data
   * @returns
   */
  static StatNum(data) {
    return request.get("/hawksystemserver/web-task/xieFeiJingQing", {
      params: data,
    });
  }

  /**
   * 警情类型统计
   *
   * @param {*} data
   * @returns
   */
  static StatType(data) {
    return request.get(
      "/hawksystemserver/web-task/percentageOfPoliceClassification", {
        params: data,
      }
    );
  }

  /**
   * 分局警情统计
   * @param {*} data
   * @returns
   */
  static StatDep(data) {
    return request.get(
      "/hawksystemserver/web-task/policeSituationOfEachBranch", {
        params: data,
      }
    );
  }

  /**
   * 24小时统计
   * @param {*} data
   * @returns
   */
  static StatHour(data) {
    return request.get("/hawksystemserver/web-task/lyAlertTrend24hour", {
      params: data,
    });
  }

  /**
   * 状态统计
   * @param {*} data
   * @returns
   */
  static StatStatus(data) {
    return request.get("/hawksystemserver/web-task/missionIntelligence", {
      params: data,
    });
  }

  /**
   * 警情类型
   * @param {*} data
   * @returns
   */
  static ListTpye(data) {
    return request.get("/hawksystemserver/jingqing/typeList", {
      params: data,
    });
  }

  /**
   * 警情派发
   * @param {*} params
   * @returns
   */
  static Edit(params) {
    return request.post("/hawksystemserver/jingqing/policeDispatch", params);
  }

  /**
   * 警情详情
   * @param {*} params
   * @returns
   */
  static Detail(id) {
    return request.get(`/hawksystemserver/jingqing/getInfoById/${id}`);
  }
  /**
   * 警情子任务详情
   * @param {*} params
   * @returns
   */
  static childDetail(id) {
    return request.get(`/hawksystemserver/task/getInfoById/${id}`);
  }
  /**
   * 轨迹接口
   * @param {*} params
   * @returns
   */
  static Track(id) {
    return request.get(`/hawksystemserver/jingqing/jingQingTrack/${id}`);
  }
  /**
   * 警情中心历史轨迹接口
   * @param {*} params
   * @returns
   */
  static HistoryTrack(params) {
    return request.get(`/hawksystemserver/jingqing/jingQingHistoryTrack`, {
      params,
    });
  }
  /**
   * 警情中心视频回放接口
   * @param {*} params
   * @returns
   */
  static HistoryVideo(params) {
    return request.get(`/hawksystemserver/jingqing/jingQingHistoryVideo`, {
      params,
    });
  }
  /**
   * 警情中心历史图片接口
   * @param {*} params
   * @returns
   */
  static HistoryPhoto(taskId) {
    // return request.get(`/hawksystemserver/jingqing/getHistoryImagesByTaskId/${taskId}`);
    return request.get(`/hawksystemserver/historical/images/${taskId}`);
  }
  /**
   * 挂载类型接口回放接口
   * @param {*} params
   * @returns
   */
  static getAllMountTypeList(params) {
    return request.get(`/hawksystemserver/mount/getAllMountTypeList `, {
      params,
    });
  }
  /**
   * 获取当天正常无人机接口
   * @param {*} params
   * @returns
   */
  static GetDrone(params) {
    return request.post(
      `/hawksystemserver/resource-report/getDeviceNormalList`,
      params
    );
  }
  /**
   * 获取鹰巢列表接口
   * @param {*} params
   * @returns
   */
  static GetEagle(params) {
    return request.post(
      `/hawksystemserver/resource-report/getNestNormalList`,
      params
    );
  }
  /**
   * 获取起降场列表接口
   * @param {*} params
   * @returns
   */
  static GetAirport(params) {
    return request.post(
      `/hawksystemserver/resource-report/getLandingNormalList`,
      params
    );
  }
  /**
   * 警情派发接口
   * @param {*} params
   * @returns
   */
  static DoPoliceDispatch(params) {
    return request.post(
      `/hawksystemserver/jingqing/policeDispatch`,
      params
    );
  }
}

export default Situation;