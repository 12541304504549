import Lockr from "lockr"
import { setAccount, setPassword } from '@/utils/auth'
import API from "@/api"
import router from "@/router"

export default {
    namespaced: true,
    state: {
        set FLYINGSESSIONID(value) {
            Lockr.set("FLYINGSESSIONID", value)
        },
        get FLYINGSESSIONID() {
            return Lockr.get("FLYINGSESSIONID")
        },
        set IDENTITY(value) {
            Lockr.set("IDENTITY", value)
        },
        get IDENTITY() {
            return Lockr.get("IDENTITY")
        },
        //存储
        set user_info(value) {
            Lockr.set("user_info", value)
        },
        //获取
        get user_info() {
            return Lockr.get("user_info")
        },
    },

    mutations: {
        SET_FLYINGSESSIONID(state, data) {
            state.FLYINGSESSIONID = data
        },
        SET_IDENTITY(state, data) {
            state.IDENTITY = data
        },
        //data赋值给user_info
        SET_USER_INFO(state, data) {
            state.user_info = data
        },
        CLEAR_USER_INFO(state) {
            state.FLYINGSESSIONID = undefined
            state.IDENTITY = undefined
            state.user_info = undefined
        },
    },

    actions: {
        Login(ctx, data){
            return new Promise((resolve, reject) => {
                API.USER.Login({
                    username: data.username,
                    password: btoa(data.password),
                    type: 1 // 登录端标识，平台：1 mmcstation：2 轻应用：3
                    // password: data.password
                }).then(res => {
                    //记住用户名密码
                    if (data.remember) {
                        setAccount(data.username)
                        setPassword(data.password)
                    }
                    //通过commit(“自定义名称”,”参数”)方法传递到mutations
                    ctx.commit('SET_FLYINGSESSIONID', res.FLYINGSESSIONID)
                    ctx.commit('SET_IDENTITY', res["mmc-identity"])
                    //返回过来的值是Res然后调用SET_USER_INFO这个方法
                    ctx.commit('SET_USER_INFO', res)
                        if(res&&res.cardStatus==0){
                        localStorage.setItem('cardStatus',true)
                        }else{
                        localStorage.removeItem('cardStatus')
                        }
                    resolve(res.data)
                }).catch(e => {
                    reject(e)
                })
            })
        },
        Logout(ctx) {
            ctx.commit('CLEAR_USER_INFO')
            router.go(0)
        },
    }
}