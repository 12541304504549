import request from "../request";

/**
 * 单位
 */
export default class Department {

    /**
     * 列表
     * @param {*} params 
     * @returns 
     */
    static List(params){
        return request.get("/hawksystemserver/home/getAllTypeStatisticsWeb", {
            params
        })
    }

    /**
     * 下拉
     * @param {*} params 
     * @returns 
     */
    static Drd(params){
        return request.get("/hawksystemserver/task/getUnitList2", {
            params
        })
    }

    /**
     * 下拉
     * @param {*} params 
     * @returns 
     */
     static DrdV2(params){
        return request.get("/hawksystemserver/organization/deviceOrganTree", {
            params
        })
    }

    /**
     * 下拉
     * @param {*} params 
     * @returns 
     */
    static getAllOrganList(params){
        return request.get("/hawksystemserver/firm/getAllOrganList", {
            params
        })
    }

}