import request, {
  request_fckernel
} from "../request";


/**
 * 飞控中心 api
 */
export default class FCKernel {
/**
   * 人脸识别结果（视图库）
   * @param {*} params
   * @returns
   */
 static getFaceResultByFileKey(params) {
  return request.get("/aiserver/face/getFaceResultByFileKey", {
    params
  });
} 

/**
   * 检测报告初始化主题
   * @param {*} params
   * @returns
   */
  static initTopic(params) {
    return request.get("/mqttclient/device/initTopic", {
      params
    });
  }
  /**
   * 检测报告初始化主题
   * @param {*} params
   * @returns
   */
  static reportExport(params) {
    return request({
      url: `/hawksystemserver/air_detection/report/export`,
      method: 'get',
      params,
      responseType: "blob"
    })
  }


  /**
   * 上传拍照图片
   * @param {*} data
   * @returns
   */
  static addTrafficTrouble(data) {
    return request.post("/hawksystemserver/trafficData/addTrafficTrouble", data);
  }
  /**
   * 下载
   * @param number reportId
   * @returns
   */
  static reportdownList(reportId) {
    return request.get(`/hawksystemserver/air_detection/report/down/list/${reportId}`);
  }
  /**
   * 停止气体检测
   * @param {*} data
   * @returns
   */
  static updateMqttTopic(data) {
    return request.put("/mqttclient/device/updateMqttTopic",
      data
    );
  }

  /**
   * 气体类型列表
   * @param {*} params
   * @returns
   */
  static air_detectionTypeList(params) {
    return request.get(`/hawksystemserver/air_detection/type/list`, {
      params
    });
  }
  //   /**
  //  * 保存气体检测图片
  //  * @param {*} data
  //  * @returns
  //  */
  //    static air_detectionImage​​​(data) {
  //     return request.post("​/hawksystemserver​/air_detection​/image​/insert", data);
  //   }
  /**
   * 气体检测历史记录表
   * @param {*} params
   * @returns
   */
  static reportList(params) {
    return request.get(`/hawksystemserver/air_detection/report/list`, {
      params
    });
  }


  /**
   * 新增调试记录
   * @param {*} data
   * @returns
   */
  static inserWebLogs(data) {
    return request.post("/hawklogs/webLogs/insertWebLogs", data);
  }
  /**
   * 无人机列表
   * @param {*} params
   * @returns
   */
  static MountedListUAV(params) {
    return request.get(`/hawksystemserver/device/organTree/`, {
      params,
    });
  }
  //     stationType: 1  //1px4 0apm
  /**
   * 无人机列表
   * @param {*} params
   * @returns
   */
  static ListUAV(params = {}) {
    return request.get(`/hawksystemserver/device/organTree/`, {
      params: {
        stationType: 1, //1px4 0apm
        search: params.search,
      },
    });
  }
  /**
   * 天气
   * @param {*} params
   * @returns
   */
  static getNestWeather(params) {
    return request.get(`/hawksystemserver/eagleNest/getNestWeather`, {
      params,
    });
  }
  /**
   * 航线下拉
   * @param {*} params
   * @returns
   */
  static DrdAirway(params) {
    return request.get(`/hawksystemserver/flight/getFlightLineList`, {
      params,
    });
  }

  /**
   * 删除
   * @param {*} params
   * @returns
   */
  static deleteTaskById(params) {
    return request.get(
      `/hawksystemserver/eagleNest/deleteTaskById/${params.id}`
    );
  }

  /**
   * 航线格式转换
   * @param {*} params
   * @returns
   */
  static TransAirway(params) {
    return request.get(`/hawksystemserver/flight/getBrokerFlightLineById`, {
      params,
    });
  }

  /**
   * 根据硬件 id 查询无人机最新数据
   * @param {*} params
   * @returns
   */
  static GetLastUavData(params) {
    return request.get("/hawksystemserver/task/getMountInfoByDeviceHardId", {
      params: params,
    });
  }

  /**
   * 根据 id 查询无人机视频信息
   * @param {*} id
   * 0 webrtc
   * 1 flv
   * 2 rtsp
   * 3 m3u8
   * @returns
   */
  static GetVideoByDeviceHardId(data) {
    return request.get(
      `/hawksystemserver/device/getIndexDeviceMountStreamAddress/${data.id}/0?onLine=${data.onLine}`
    );
  }

  /**
   * 根据设备id获取视频流集合
   * @param {*} data 
   * @returns 
   */
  static GetDeviceStreamAddressByDeviceId(data) {
    return request.get(
      `/hawksystemserver/device/getDeviceStreamAddressByDeviceId/${data.deviceHardId}`
    );
  }

  /**
   * 判断用户是否有无人机接管权限
   * @param {*} params
   * @returns
   */
  static getTakeOverInfo(params) {
    return request.get("/hawksystemserver/device/getTakeOverInfo", {
      params: params,
    });
  }
  /**
   * 判断用户是否有无人机控制权限
   * @param {*} params
   * @returns
   */
  static checkUseOperate(params) {
    return request.get("/hawksystemserver/device/checkUseOperate", {
      params: params,
    });
  }

  /**
   * 退出无人机接管
   * @param {*} params
   * @returns
   */
  static exitTaskOver(params) {
    return request.get("/hawksystemserver/device/exitTakeOver", {
      params: params,
    });
  }

  /**
   * 用户申请接管无人机
   * @param {*} params
   * @returns
   */
  static checkUavControl(params) {
    return request.get("/hawksystemserver/device/checkUavControl", {
      params: params,
    });
  }

  /**
   * 修改无人机的接管状态
   * @param {*} params
   * @returns
   */
  static updateUavControlStatus(params) {
    return request.get("/hawksystemserver/device/updateUavControlStatus", {
      params: params,
    });
  }

  /**
   * 查询接管列表
   * @param {*} params
   * @returns
   */
  static getAllTakeOverList(params) {
    return request.get("/hawksystemserver/device/getAllTakeOverList", {
      params: params,
    });
  }

  /**
   *  任务库 -任务记录
   * @param {*} params
   * @returns
   */
  static addTaskAndUserRelation(data) {
    return request.post("/hawksystemserver/task/addTaskAndUserRelation", data);
  }

  /**
   * 查询寻当前用户关联的任务
   * @param {*} params
   * @returns
   */
  static getTaskAndUserRelation(data) {
    return request.get(
      `/hawksystemserver/task/getTaskAndUserRelation/${data.sourceType}`, {
        params: {
          deviceHardId: data.deviceHardId,
        },
      }
    );
  }
  /**
   * 查询鹰巢执行什么任务
   * @param {*} params
   * @returns
   */
  static getNestTaskingType(id) {
    return request.get(
      `/hawksystemserver/eagleNest/getNestTaskingType/${id}`,
    );
  }
  /**
   * 修改用户和任务关联的方式
   * @param {*} params
   * @returns
   */
  static updateTaskAndUserRelation(data) {
    return request.put(
      "/hawksystemserver/task/updateTaskAndUserRelation",
      data
    );
  }

  /**
   * C查询鹰巢是否有任务冲突
   * @param {*} params
   * @returns
   */
  static getNestTaskByTime(params) {
    return request.get(
      `/hawksystemserver/eagleNest/getNestTaskByTime/${params.nestId}`
    );
  }

  //视图列表
  static photoAndvideo(visitQueryForm) {
    return request.post("/upload/visit/taskList",
      visitQueryForm,
    );
  }
  //视图删除
  static deletephotoAndvideo(params) {
    return request.delete("/upload/visit/delList", {
      params: params
    });
  }
  //机载视图列表
  static devicephoto(params) {
    return request.get(process.env.VUE_APP_LOOK_URL + "/log-file-record/list", //52
      // return request.get("http://32.128.6.70:20251/log-file-record/list", //48
      {
        params
      }
    );
  }
  //机载视图删除
  static deletelphoto(params) {
    return request.get(process.env.VUE_APP_LOOK_URL + `/log-file-record/delete?${params}`); //52
    // return request.get(`http://32.128.6.70:20251/log-file-record/delete?${params}`);//48
  }
  //鹰巢解禁
  static templgnoreWeatherCheck(params) {
    return request.get(process.env.VUE_APP_LOOK_URL + `/api/apron/tempIgnoreWeatherCheck/${params}/15`); //52
    // return request.get(`http://32.128.6.70:20251/log-file-record/delete?${params}`);//48
  }
  //视图上传
  static Upload(data) {
    return request({
      url: `/upload/visit/insertByFile`,
      method: "post",
      data,
    });
  }
  //视图上传
  static Uploads(data) {
    return request({
      url: `/upload/visit/insertByFiles`,
      method: "post",
      data,
    });
  }
  //鹰巢周期历史
  static history(params) {
    return request.get("/fkzx-task/record/list", //52
      {
        params
      }
    );
  }
  //文件转url
  static fileUrl(url) {
    return request({
      url: url,
      method: "get",
      responseType: "blob"
    })
  }
  /**
   * 运行监测日志
   * @param {*} data
   * @returns
   */
  static saveDeviceRunMonitorLog(data) {
    return request.post(
      '/hawksystemserver/UavLog/saveDeviceRunMonitorLog', data);
  }
  /**
   * 新增设备异常记录
   * @param {*} data
   * @returns
   */
  static exceptionAdd(data) {
    return request.post(
      '/hawksystemserver/device_exception_log/add', data);
  }
  /**
   * 设备异常记录列表
   * @param {*} params
   * @returns
   */
  static findAll(params) {
    return request.get(
      '/hawksystemserver/device_exception_log/findAll', {
        params,
      });
  }
  /**
   * 人群密度截帧
   * @param {*} params
   * @returns
   */
  static crowdDensity(params) {
    return request({
      url: `/aiserver/face/crowdDensity`,
      method: "get",
      params: params
    });
  }
  /**
   * 游泳检测
   * @param {*} data
   * @returns
   */
  static insertSwimmingMonitor(data) {
    return request({
      url: `/aiserver/swimmingMonitor/insertSwimmingMonitor`,
      method: "post",
      data
    });
  }
  /**
   * 共达地车牌
   * @param {*} data
   * @returns
   */
   static addPlateInfoByNumber(data) {
    return request({
      url: `/aiserver/plate/addPlateInfoByNumber`,
      method: "post",
      data
    });
  }

  /**
   * 周期任务
   * @param {*} data
   * @returns
   */
  static updateNestTaskEffect(data) {
    return request({
      url: `/hawksystemserver/eagleNest/updateNestTaskEffect`,
      method: "put",
      data
    });
  }
  /**
   * 基站检测
   * @param {*} params
   * @returns
   */
  static getStationSignalLog(params) {
    return request({
      url: `/hawksystemserver/base/getStationSignalLog`,
      method: "get",
      params: params
    });
  }
    /**
   * 基站信号区域
   * @param {*} params
   */
     static singleList(params) {
      return request({
        url: `/hawksystemserver/base/netCoverageArea/list`,
        method: 'get',
        params
      })
    }
    //区域航线规划
  static spaceLine(data) {
    return request.post(process.env.VUE_APP_LOOK_URL + "/route/scan_area", //52
    // Headers:{
    //   con
    // },  
    data
    );
  }
}