import request from "../request"

class Pilot {

    /**
     * 学时统计
     * @param {*} params 
     */
    static StatClassHours(params){
        return request.get("/hawksystemserver/home/classHourTotal", {
            params
        })
    }

}

export default Pilot