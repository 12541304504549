import Vue from 'vue'
import VueRouter from "vue-router"
Vue.use(VueRouter)
export const createRouter = () =>
  new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: [
      {
        path: "/login",
        component: () => import(`@/pages/login`)
      },
      {
        path: "/videos",
        component: () => import("@/components/videosPage"),
      },
      {
        path: "/screenDisplay",
        component: () => import("@/pages/boss/index.vue"),
      },
      {
        path: "",
        component: () => import("@/components/layout"),
        redirect: "/home",
        children: [
          // {
          //   path: "/eagleStrike",
          //   component: () => import("@/pages/eagleStrike"),
          // },
          {
            path: "/eagleStrike",
            component: () => import("@/pages/eagleStrike/indexNew.vue"),
          },
          {
            path: "/home",
            // component: () => import("@/pages/home/index.vue"),
            redirect: "/observe/home",
          },
          {
            path: "/command",
            name: "command",
            component: () => import("@/pages/command"),

          },
          /* {
            path: "/command_v1",
            component: () => import("@/pages/command/home/index_new.vue"),
          }, */
          {
            path: "/observe",
            redirect: "/observe/home",
          },
          {
            path: "/observe/home",
            component: () => import("@/pages/observe/home"),
            redirect: "/observe/home/1",
            children: [
              {
                path: "/observe/home/1",
                component: () => import("@/pages/observe/home/index1"),
              },
              {
                path: "/observe/home/2",
                component: () => import("@/pages/observe/home/index2"),
              },
            ],
          },
          {
            path: "/accident",
            component: () => import("@/pages/strike"),
            // component: () => import("@/pages/accident"),
          },
          {
            path: "/fckernel",
            component: () => import("@/pages/observe/fckernel"),
          },
          {
            path: "/fckernelVideos",
            component: () => import("@/pages/observe/fckernelVideos"),
          },
          {
            path: "/server_details",
            component: () => import("@/pages/server_details/index.vue"),
          },
        ],
      },
    ],
  });

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router