import Vue from 'vue'
import { MessageBox } from 'element-ui'

let ConfirmPlugin = {
    install(Vue, options) {
        Vue.prototype.$el_confirm = (message, successCb = () => { }, failCb = () => { }, opt) => {
            return new Promise((resolve) => {
                MessageBox.confirm(message, '温馨提示', {
                    center: true,
                    callback: action => {
                        if (action === 'confirm') {
                            successCb()
                            resolve('confirm')
                        } else if (action == 'cancel') {
                            if (failCb && typeof failCb == 'function')
                                failCb()
                        }
                    },
                    ...opt
                })
            })
        }
    }
}

Vue.use(ConfirmPlugin)

export default ConfirmPlugin