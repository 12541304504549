import { request_server } from "../request"

export default class server {
    static jump(data) {
        return request_server.post('http://32.128.6.52:8084/monitor/grafana/login',data)
    }
    /**
      * 服务器总览
      * @param {*} params
      * @returns
      */
    static getServer() {
        return request_server.get('/monitor/overview')
    }

    /**
    * 服务器CPU利用率
    * @param {*} params
    * @returns
    */
    static getServerCpu(params, instance) {
        let a = `/monitor/host/${instance}/cpuUtilization`
        return request_server.get(a, {
            params,
        })
    }

     /**
    * 服务器内存利用率
    * @param {*} params
    * @returns
    */
      static getMemory(params, instance) {
        let a = `/monitor/host/${instance}/memoryUsage`
        return request_server.get(a, {
            params,
        })
    }
        /**
* 容器所有数据监控
* @param {*} params
* @returns
*/
static getVessel(params, instance, containerName) {
    let a = `/monitor/host/${instance}/container/${containerName}/allUtilization`
    return request_server.get(a, {
        params,
    })
}

    /**
* 容器内存利用率
* @param {*} params
* @returns
*/
    static getServerMemory(params, instance) {
        let a = `/monitor/host/${instance}/memoryUsage`
        return request_server.get(a, {
            params,
        })
    }
    /**
* 流量速度监控(流出)服务器
* @param {*} params
* @returns
*/
    static getServerflux(params, instance) {
        // /monitor/host/{instance}/networkTxBps
        let a = `/monitor/host/${instance}/networkTxBps`
        return request_server.get(a, {
            params,
        })
    }


    /**
* 流量速度监控(流入)
* @param {*} params
* @returns
*/
    static getInflux(params, instance) {
        let a = `/monitor/host/${instance}/networkRxBps`
        return request_server.get(a, {
            params,
        })
    }

    /**
* 容器cpu监控
* @param {*} params
* @returns
*/
    static getVesselCPU(params, instance, containerName) {
        let a = `/monitor/host/${instance}/container/${containerName}/cpuUtilization`
        return request_server.get(a, {
            params,
        })
    }
    /**
* 容器内存监控
* @param {*} params
* @returns
*/
    static getinternal_storage(params, instance, containerName) {
        let a = `/monitor/host/${instance}/container/${containerName}/memoryUsage`
        return request_server.get(a, {
            params,
        })
    }
   
        /**
* 容器流量监控(流出)
* @param {*} params
* @returns
*/
static getoutflow(params, instance, containerName) {
    let a = `/monitor/host/${instance}/container/${containerName}/networkTxBps`
    return request_server.get(a, {
        params,
    })
}
}