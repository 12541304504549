import Cookies from 'js-cookie'

const account_key = 'mmc-jianzhai-vue_account'
const password_key = 'mmc-jianzhai-vue_password'

/**
 * 获取账号信息
 */
export function getAccount() {
    return Cookies.get(account_key)
}

/**
 * 设置账号信息
 * @param {*} account 
 */
export function setAccount(account) {
    return Cookies.set(account_key, account, { expires: 7 })
}

/**
 * 获取密码信息
 */
export function getPassword() {
    return Cookies.get(password_key) 
}

/**
 * 设置密码信息
 * @param {*} password 
 */
export function setPassword(password) {
    return Cookies.set(password_key, password, { expires: 7 })
}
