import{ request_qingliu } from "../request";
class Qingliu {
  /**
   * 一键通知
   * @param {*} params
   * @returns
   */
   static qoeInform(data) {
    return request_qingliu.post(
      '/qoe',
      data
    )
  }

}

export default Qingliu