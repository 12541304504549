import Vue from "vue"
import interact from "interactjs"

Vue.directive("interact", {
    inserted(el, binding, vnode) {
        let val = binding.value
        let it = interact(el)
            .draggable({
                listeners: {
                    start(event) {
                        let els = document.querySelectorAll('.cpt-dialog')
                        for (let i = 0; i < els.length; i++) {
                            if(els[i].style.zIndex == 99 || els[i].style.zIndex == 88){
                                els[i].style.zIndex = 88
                            }else{
                                els[i].style.zIndex = 10
                            }
                        }

                        if(val && val.top){
                            el.style.zIndex = 99
                        }else{
                            el.style.zIndex = 20
                        }
                        el.parentElement.style.zIndex = 999
                    },
                    move(event) {
                        var target = event.target.parentElement
                            // keep the dragged position in the data-x/data-y attributes
                        var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
                        var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

                        // translate the element
                        target.style.webkitTransform =
                            target.style.transform =
                            'translate(' + x + 'px, ' + y + 'px)'

                        // update the posiion attributes
                        target.setAttribute('data-x', x)
                        target.setAttribute('data-y', y)
                    }
                },
                inertia: true,
                modifiers: [
                    interact.modifiers.restrictRect({
                        restriction: 'body',
                        endOnly: true
                    })
                ]
            })
        if (val && val.resize) {
            it.resizable({
                // resize from all edges and corners
                edges: { left: true, right: true, bottom: true, top: true },

                listeners: {
                    move(event) {
                        var target = event.target
                        var x = (parseFloat(target.getAttribute('data-x')) || 0)
                        var y = (parseFloat(target.getAttribute('data-y')) || 0)

                        // update the element's style
                        target.style.width = event.rect.width + 'px'
                        target.style.height = event.rect.height + 'px'

                        // translate when resizing from top or left edges
                        x += event.deltaRect.left
                        y += event.deltaRect.top

                        target.style.webkitTransform = target.style.transform =
                            'translate(' + x + 'px,' + y + 'px)'

                        target.setAttribute('data-x', x)
                        target.setAttribute('data-y', y)
                            // target.textContent = Math.round(event.rect.width) + '\u00D7' + Math.round(event.rect.height)
                    }
                },
                modifiers: [
                    // keep the edges inside the parent
                    interact.modifiers.restrictEdges({
                        outer: 'parent'
                    }),

                    // minimum size
                    interact.modifiers.restrictSize({
                        min: { width: 100, height: 50 }
                    })
                ],

                inertia: true
            })
        }
    }
})
