import request from "../request"

export default class Examine { 
  /**
     * 列表
     * @param {*} params 
     * @returns 
     */
  static List(params) {
    return request.get(`hawksystemserver/flightMission/statisticsTaskData`, {
      params
  })
  }
  /**
   * 假日类型
   * @param {*} params
   * @returns
   */
  static getHolidayPatrolList(params) {
    return request({
      url: '/hawksystemserver/flightMission/getHolidayPatrolList',
      method: 'get',
      params,
    })
  }
}