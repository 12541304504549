import request from '../request'

/**
 * 空域
 */
export default class Battery {
    /**
     * 列表
     * @param {*} params
     * @returns
     */
    static List(params) {
        return request.get('/hawksystemserver/battery/getBatteryList', {
            params: params,
        })
    }
    /**
   * 电池编号是否存在
   * @param {*} params
   * @returns
   */
  static IsExist(params) {
    return request({
      url: `/hawksystemserver/battery/isExistByCode`,
      method: 'get',
      params,
    })
    }
    /**
   * 归属无人机
   * @param {*} params
   * @returns
   */
  static getUav(params) {
    return request({
      url: `/hawksystemserver/battery/uav_list`,
      method: 'get',
      params,
    })
  }
        /**
     * 任务场景列表
     * @param {*} params
     * @returns
     */
         static getAllTaskCenarioList(params) {
            return request.get('/hawksystemserver/jingqing/getAllTaskCenarioList', {
                params: params,
            })
    }
    /**
   * 电池详情
   * @param {*} id
   * @returns
   */
  static Detail(id) {
    return request({
      url: `/hawksystemserver/battery/getInfoById/${id}`,
      method: 'get',
    })
    }
    /**
   * 修改电池状态
   * @param {*} id
   * @returns
   */
  static EditStatus(id) {
    return request({
      url: `/hawksystemserver/battery/delete/${id}`,
      method: 'get',
    })
    }
    /**
   * 编辑电池
   * @param {*} data
   * @returns
   */
  static Edit(data) {
    return request({
      url: '/hawksystemserver/battery/update',
      method: 'post',
      data,
    })
  }
}
