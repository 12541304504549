import axios from "axios";
import request from "../request";

const APPID = "05ae065eeb8547c584fc17cda6f54bd6";

class Weather {
  // /**
  //  * 获取天气
  //  * @param {*} params
  //  */
  // static get(params){
  //     return axios({
  //         url: "https://weather01.market.alicloudapi.com/hour24",
  //         method: "get",
  //         headers: {
  //           Authorization: "APPCODE" + " " + APPID,
  //         },
  //         params: {
  //             area: "盐城",
  //             ...params
  //         }
  //     })
  // }


   // 获取天气区县
   static getSevenOrg(params) {
    return request.get("/hawksystemserver/home/getSevenOrg", {
      params: params,
    });
  }
  // 获取服务器时间
  static getDate(params) {
    return request.get("/hawksystemserver/home/getDate", {
      params: params,
    });
  }
  static get(params) {
    return request.get("/hawksystemserver/home/get-24-weather", {
      params: params,
    });
  }
  static getNestWeather(params) {
    return request.get("/hawksystemserver/home/getNestWeather", {
      params: params,
    });
  }

  static getNewWeather(params) {
    return request.get("/hawksystemserver/home/getCurrentWeatherBylocation", {
      params: params,
    });
  }
  /**
   * 总览页当天单位报备打卡情况列表
   * @param {*} params
   * @return
   */
  static getCardInfosOrg(params) {
    return request.get("/hawksystemserver/person-management/getCardInfosOrg", {
      params,
    });
  }
  
}

export default Weather;
