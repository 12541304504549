export default {
  namespaced: true,

  state: {
    isShow: true,
    toolbarIsShow: true,
    isShowFactorIcons: true,
    taskType: "",
    flag:false,// 全要素是否折叠
    mapFlag:false,// 地圖
    waterFlag:false,//水质检测
    waterbox:false,//水质检测表
    videoFlag:false,//飞控视频展示
    move_data:{},//飞控中心 指屏幕点移动
    move_ys_data:{},// 鹰视
    is3d:false,
    
    key_line:null,//刷新飞行任务-警情任务航线规划中的航线列表
    jq_list:null,//刷新飞行任务-警情任务航线规划
    ctls_list:null,//刷新飞行任务-常态临时任务

    isRcConnectingType:null,//自动切换摇杆权限
    yc_line_load:null,//鹰巢任务航线加载
    safetyWarning:null,//安全预警列表刷新
    ygisCenterFlag:false,//摇杆是否在中位
    ygValue:null,//摇杆值,
    warningNotice:true,//是否暂时预警通知
    eo_zoom:null,
   GASList_flag:false,
   gastopic:null
  },

  mutations: {
    SET_GASTOPIC:(state,data)=>{
      state.gastopic = data
    },
    SET_MMCGimbalGASList_FLAG:(state,data)=>{
      state.GASList_flag = data
    },
    SET_EO_ZOOM:(state,data)=>{
      state.eo_zoom = data
    },
    SET_WARNING_NOTICE:(state,data)=>{
      state.warningNotice = data
    },
    SET_YG_IS_CENTER_FLAG:(state,data)=>{
      state.ygisCenterFlag = data
    },
    SET_YG_VAlUE:(state,data)=>{
      state.ygValue = data
    },
    SET_SAFETY_WARNING:(state,data)=>{
      state.safetyWarning = data
    },
    SET_YC_LINE_LOAD:(state,data)=>{
      state.yc_line_load = data
    },
    SET_IS_RC_C0NNECTING_TYPE:(state,data)=>{
      state.isRcConnectingType = data
    },
    SET_JQ_LIST:(state,data)=>{
      state.jq_list = data
    },
 SET_CTLS_LIST:(state,data)=>{
      state.ctls_list = data
    },
    SET_KYY_LINE:(state,data)=>{
      state.key_line = data

    },
    SET_3D_STATUS:(state,data)=>{
      state.is3d = data

    },
    SET_MOVE_YS_DATA:(state, data)=>{
      state.move_ys_data = data
    },
    SET_MOVE_DATA:(state, data)=>{
      state.move_data = data
    },
    SET_VIDEO:(state, data)=>{
      state.videoFlag = data
    },
    IS_SHOW: (state, data) => {
      state.isShow = data
    },
    TOOLBAR_IS_SHOW: (state, data) => {
      state.toolbarIsShow = data
   
    },
    SET_TASKTYPE: (state, data) => {
      state.taskType = data
    },
    IS_SHOW_FACTOR_ICONS: (state, data) => {
      console.log("IS_SHOW_FACTOR_ICONS");
      state.isShowFactorIcons = data
    },
    
    SET_FlAG:(state, data)=>{
      state.flag=data
    },
    SET_MAPFLAG:(state, data)=>{
      state.mapFlag=data
    },
    SET_WATERFlag:(state, data)=>{
      state.waterFlag=data
    },
    SET_WATERBOX:(state, data)=>{
      state.waterbox=data
    }
    
  },

  actions: {},
}
