import { request_localhost } from '../request'
import request from '../request'

export default class bendi {
      //本地数据信息
  static listaaaa(params) {
    return request_localhost.get("5001/monitor/pc_info", {
      params
    })
  }
}