import request from '../request'

export default class Tower {

    /**
     * 航线列表
     * @param {*} params 
     * @returns 
     */
    static ListAirway(params) {
        return request.get(`/hawksystemserver/task2uav/getTaskFlightLineSelect`, {
            params
        })
    }

    /**
     * 所有航线列表
     * @param {*} params 
     * @returns 
     */
    static GetAllAirway(params) {
        return request.get(`/hawksystemserver/task2uav/getTaskFlightLineAll`, {
            params
        })
    }
    
    /**
     * 所有航线列表
     * @param {*} params 
     * @returns 
     */
    static ListStation(params) {
        return request.get(`/hawksystemserver/task2uav/baseList`, {
            params
        })
    }

    /**
     * 无人机预警
     * @param {*} params
     * @returns
     */
    static DeviceWarning(params) {
        return request.get('/hawksystemserver/task2uav/deviceWarningList', {params})
    }

    /**
     * 鹰巢预警
     * @param {*} params
     * @returns
     */
     static NestWarning(params) {
        return request.get('/hawksystemserver/task2uav/nestWarningList', {params})
    }
    
    /**
     * 基站预警
     * @param {*} params
     * @returns
     */
    static BaseWarning(params) {
        return request.get('/hawksystemserver/task2uav/baseWarningList', {params})
    }
    
    /**
     * 警情列表
     * @param {*} params
     * @returns
     */
    static JingQingList(params) {
        return request.get('/hawksystemserver/jingqing/getAllJingQingList3', {params})
    }

    static FlightLog(params) {
        return request.get(`/hawksystemserver/flightLog/${params.id}`)
    }
}