import Vue from 'vue'
import App from './App.vue'
import './lib/s3m'

// 全局变量
import {
  message
} from '@/utils/resetMessage'
import g_data from '@/utils/global.js'
Vue.prototype.g_data = g_data

Vue.config.productionTip = false

import mixin from './mixin/mixin'
Vue.mixin(mixin);

// element滚动插件
import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);
// 时间插件
import moment from "moment";
Vue.prototype.$moment = moment;

import "normalize.css/normalize.css"
import "./styles/index.scss"

import "./utils/lod"

import router from "./router"
import "./router/permission"

import store from "./store"

import "./plugins"
import "./directives"

//引入阿里巴巴矢量图标库
import "./assets/ali_font/iconfont.css"

// import echarts from "echarts";
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;

Vue.prototype.$message = message;

import recover from "@/plugins/recover";
Vue.prototype.$recover = recover;
import VueClipBoard from "vue-clipboard2";
Vue.use(VueClipBoard);
window.$debug = localStorage.getItem('$debug') == 1 ? true : false;    //全局调试信息展示开关
window.$log = function(...args){
  // console.log(args,'1111');
  if(window.$debug){
    console.info(...args);
  }
}

window.$this = {};      //用于储存测试用的实例

import Bus from "@/assets/ligature.js";
window.$Bus = Bus;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')



// window.onerror = function(...args){
//   console.log("onerror", args);
//   return false;
// }