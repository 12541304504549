import request from '../request'

/**
 * 空域
 */
export default class Accident {
    /*
* @param {*} params
* @returns
*/
    static getTaskTypeIdByGuide(params) {
        return request.get('/hawksystemserver/task/getTaskTypeIdByGuide', {
            params: params,
        })
    }
    /**
 *导出现场事故图报告
 * @param {*} id
 * @returns
 */
    static TrafficWord(id) {
        return request({
            url: `/hawksystemserver/export/exportTrafficWord?id=${id}&imageType=1`,
            method: "get",
            responseType: 'blob'
        });
    }

    /* 亮尾列表
     * @param {*} params
     * @returns
     */
    static updateTaskProcess(params) {
        return request.post('/hawksystemserver/process/updateTaskProcess', params)
    }
    /* 河道确认
* @param {*} params
* @returns
*/
    static createGuideAction(params) {
        return request.post('/hawksystemserver/process/createGuideAction', params)
    }
    /*
* @param {*} params
* @returns 无人机应用列表
*/
    static strikeOrganTree(params) {
        return request.get('hawksystemserver/device/strikeOrganTree', {
            params: params,
        })
    }
    /* 河道列表
* @param {*} params
* @returns
*/
    static getTaskGuide(params) {
        return request.get('/hawksystemserver/process/getTaskGuide', {
            params: params,
        })
    }

    /**
     * 亮尾查询状态
     * @param {*} params
     * @returns
     */
    static getProcessd(params) {
        return request.get('/hawksystemserver/process/getProcess', {
            params: params,
        })
    }
    /**
     * 亮尾查询状态
     * @param {*} params
     * @returns
     */
    static getTaskProcess(params) {
        return request.get('/hawksystemserver/process/getTaskProcess', {
            params: params,
        })
    }
    /**
     * 列表
     * @param {*} params
     * @returns
     */
    static List(params) {
        return request.get('/hawksystemserver/task/traffic_task', {
            params: params,
        })
    }
    /**
     * 鹰击点击无人机展示其对应挂载
     * @param {*} params
     * @returns
     */
    static getMountInfoByDeviceHardId(params) {
        return request.get('/hawksystemserver/task/getMountInfoByDeviceHardId', {
            params: params,
        })
    }

    /**
     * 点击无人机展示其对应基站干扰值
     * @param {*} id
     * @returns
     */
    static getSNIRByDeviceHardId(id) {
        return request.get(`/hawksystemserver/uavTakeoffChecklist/getSNIRByDeviceHardId/${id}`)
    }
    /**
     * 查询指令按钮
     * @param {*} params
     * @returns
     */
    static getTaskGuideKey(params) {
        return request.get('/hawksystemserver/process/getTaskGuideKey', {
            params: params,
        })
    }





    /**  查询当前无人机执行的是哪个场景的任务
     * @param {*} params
     * @returns
     */
    static getCenarioByDeviceHardId(params) {
        return request.get('/hawksystemserver/task/getCenarioByDeviceHardId', {
            params: params,
        })
    }
    /**  生成报告
 * @param {*} params
 * @returns
 */
    static exportTaskWord(params) {
        return request.get('/hawksystemserver/task/exportTaskWord', {
            params: params,
        })
    }

    /**
    * 登录
    * @param {*} data
    * @returns
    */
    static createTaskWord(data) {
        return request.post('/hawksystemserver/task/createTaskWord', data)
    }


    /** 水质检测
* @param {*} params
* @returns
*/
    static Waterqualitydetection(params) {
        return request.get('/hawksystemserver/serviceDataInfo/getServiceDataStrike', {
            params: params,
        })
    }

    /** 水质检测
* @param {*} params
* @returns
*/
    static Watetype(params) {
        return request.get('/hawksystemserver/serviceDataInfo/getServiceTestType', {
            params: params,
        })
    }

    /** 任务类型
* @param {*} params
* @returns
*/
    static getTaskTypeIdByGuide(params) {
        return request.get('/hawksystemserver/task/getTaskTypeIdByGuide', {
            params: params,
        })
    }
    //获取已完成任务
    /** 任务类型
* @param {*} params
* @returns
*/
    static taskNamesByTypeId(params) {
        return request.get(`/hawksystemserver/historical/taskNamesByTypeId/${params.id}`)
    }
    /** 
    * @param {*} params
    * @returns
    */
    static taskCountByTypeId(params) {
        return request.get(`/hawksystemserver/historical/taskCountByTypeId/${params.id}`)
    }
    static historicalIMG(params) {
        return request.get(`/hawksystemserver/historical/images/${params.id}`)
    }
    static historicalvideos(params) {
        return request.get(`/hawksystemserver/historical/videos/${params.id}`)
    }

    // 历史图片
}