import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)

import getters from './getters'

function SET_STATE(state, data){
  state[data.key] = data.value;
}

const ctx = require.context('./modules', true, /\.js$/)
let modules = ctx.keys().reduce((modules, key) => {
    // ./app.js => app
    let name = key.replace(/^\.\/(.*)\.js$/, '$1'),
        value = ctx(key).default
    //为每个模块的mutaions写入设置state的方法
    if(value.mutations){
      value.mutations.SET_STATE = SET_STATE;
    }else{
      value.mutations = {
        SET_STATE,
      };
    }
    modules[name] = value
    return modules
}, {})
const store = new Vuex.Store({
    modules,
    getters,
})

export default store