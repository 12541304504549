import request from "../request"

export default class Res {

    /**
     * 警力资源统计
     * @param {*} params 
     * @returns 
     */
    static Stat(params){
        return request.get("/hawksystemserver/home/policeResources", {
            params
        })
    }

}