<template>
      <router-view />
</template>
<script>
export default {
  data() {
    return {
      visible: true,
      user_info:null
    };
  },
  watch: {
    user_info: {
      handler(val) {
        if (!val) {
          this.$router.push("/login")
        }
      }
    }
  },
  mounted(){
    // this.resetLog()
    setInterval(() => {
      this.user_info = localStorage.getItem("user_info")
    }, 1000);
  },
  methods: {
    resetLog(){
      console.log = (function(log){
        return process.env.VUE_APP_SWTX_ENV == "production" ? function(){} : log
      }(console.log))
    }
  }
};
</script>


<style lang="scss" scoped>
.app {
  // min-width: 2560px;
  // min-height: 1080px;
  // width: 2560px;
  // height: 1080px;
  // overflow: auto;
}
</style>