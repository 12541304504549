switch (process.env.VUE_APP_ENV_TYPE) {
  case "公网":
    window.___s3m = [
      "3D-DH-LHZ",
      "3D-FSJD",
      "3D-HY-GXQ",
      "3D-SX-TY",
      "3D-SZ-SLSQ",
      "3D-ZQYS",
      "3D-SCYTS",
      "3D-SZ-FT",
      "3D-GZ",
    ];
    break;

  default:
    window.___s3m = [
      "3D-9-1",
      "3D-9-2",
      "3D-9-3",
      "3D-9-4",
      "3D-9-4-bu",
      // "3D-13-1",
      // "3D-13-2",
      "3D-LGZ-1",
      "3D-XFZ",
      "3D-XXZ-1-2",
      "3D-XXZ-2-2",
      "3D-XXZ-3-2",
      "3D-YDZ",
      // 永丰镇
      "3D-YFZ-JM",

      // 秦南镇
      // 龙岗镇 JM
      "3D-DGZ-JM-1",
      "3D-DGZ-JM-2",
      "3D-XXZ-3-3",
      //数梦小镇
      "3D-SMXZ",
      //珠溪古镇
      "3D-ZXGZ",
      //先锋岛
      "3D-XFD",
      //金茂府周边
      "3D-JMFZB",
      //特警基地附近
      "3D-TJJD",
      // 2023年更新
      "3D-YDZ",
      "3D-QK15-2",
      "3D-1619",
      "3D-QK12",
      "3D-QK14-6",
      "3D-BCZ-2",
      "3D-QK18-2",
      "3D-dgz",
      "3D-QK3",
      "3D-QK5-S3MB",
      "3D-QK10_11S3MB_2023",
      "3D-YiQiQK10-2S3MB",
      "3D-YiQiQK10-3-S3MB",
      "3D-YiQiQK11-1S3MB",
      "3D-YiQiQK11-2-S3MB",
      "3D-YiQiQK11-3-S3MB",
      "3D-QK1",
      "3D-QK2",
      "3D-QK6_2021_S3MB",
      "3D-QK7_2021_S3MB",
      "3D-QK8-2-S3MB",
      "3D-QK8-5-S3MB",
      "3D-QK9_S3MB",
      // 粗模
      "3D-_2_S3MB",
      "3D-_1_S3MB_JM",
      "3D-DZHZ-S3MB",
      "3D-GW-S3MB",
      "3D-LG-S3MB",
      "3D-LW-S3MB",
      // 南洋镇
      "3D-NY-S3MB",
      "3D-QN-S3MB",
      "3D-SZ-S3MB",
      "3D-XF-S3MB",
      "3D-17S3MB",
      "3D-QK8-3-S3MB",
      "3D-QK8-4-S3MB",
      "3D-QK13_S3M7",
      "3D-BJ-S3MB-JM",
      "3D-GM-S3MB-JM",
      "3D-QK6-S3MB-JM",
      "3D-QK7-S3MB-JM",
      "3D-QK8-S3MB-JM",
      "3D-YF-S3MB-JM",
      "3D-ZZJD-S3MB-JM",
      "3D-XX-S3MB-JM",
      //  10-21 部署
      "3D-QK4-X",
      "3D-DZHDJQ",
      "3D-BLGZ",
      "3D-GWSQ-2",
      "3D-NYZ",
      "3D-LWZ-2",
      "3D-DZH",
      "3D-XFZ-4",
      "3D-SZZ-2",
      "3D-QK13_S3M"
    ];
    break;
}