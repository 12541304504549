import Vue from 'vue'
import { message } from "@/components/message"

let MessagePlugin = {
    install(Vue, options) {
        Vue.prototype.$el_message = (msg, closeCb, type = 'success', duration = 1500) => {
            message({
                message: msg,
                type,
                duration,
                onClose: async() => {
                    if (closeCb && typeof closeCb === "function") {
                        closeCb()
                    }
                }
            });
        }
    }
}

Vue.use(MessagePlugin)

export default MessagePlugin