import request from '../request'

/**
 * 空域
 */
export default class Serve {
    /**
     * 列表
     * @param {*} 
     * @returns
     */
    static List() {
        return request.get('/hawksystemserver/serviceCatalog/areaData')
    }
}