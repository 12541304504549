// 解决飞控无人机  手动-自动显示问题
// 首先获得定点模式，判断是否接管无人机，判断是否连接遥感
export default {
    namespaced: true,
    state: {
        flag:0,
        Mstate:'',//无人机当前处于什么模式
    },

    mutations: {
        tai(state,num) {
          state.flag = num
        },
        // 存储当前模式
        compose_mode(state,string){
            state.Mstate = string
        }
    },

    actions: {},
}