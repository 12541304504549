import request from "../request"

export default class Device {

    /**
     * 设备列表
     * @param {*} params 
     * @returns 
     */
    static List(params) {
        let {
            cate = 1, ...rest
        } = params
        let cate_map = {
            1: "/hawksystemserver/device/organTree"
        }
        return request.get(cate_map[cate], {
            params: rest
        })
    }
    static getDeviceStreamList(params) {
        return request.get(`hawksystemserver/device/getDeviceStreamList`, {
            params
        })
    }
    /**
     * 无人机型号列表
     * @param {*} params 
     * @returns 
     */
    static ListTypeAll(params) {
        return request.get(`hawksystemserver/device/goods_list`, {
            params
        })
    }
    // 强制接管
    static forceTakeOver(params) {
        return request.get(`/hawksystemserver/device/forceTakeOver`, {
            params
        })
    }

   
    /**
     * 无人机类型列表
     * @param {*} params 
     * @returns 
     */
    static ListCate(params) {
        return request.get(`hawksystemserver/device/deviceCateList`, {
            params
        })
    }
    /**
     * 无人机注册
     * @param {*} data
     * @returns
     */
    static Add(data) {
        return request({
            url: '/hawksystemserver/device/createDevice',
            method: 'post',
            data,
        })
    }
    /**
   * 无人机修改
   * @param {*} data
   * @returns
   */
  static Edit(data) {
    return request({
      url: '/hawksystemserver/device/modifyInfo',
      method: 'post',
      data,
    })
  }
    /**
   * 详细信息
   * @param {*} id
   */
  static Detail(id) {
    return request({
      url: `/hawksystemserver/device/getInfoById/${id}`,
      method: 'get',
    })
    }
    /**
   * 修改无人机状态
   * @param {*} id
   */
  static EditStatus(id) {
    return request({
      url: `/hawksystemserver/device/delDevice/${id}`,
      method: 'get',
    })
  }
    /**
     * 角色列表
     * @param {*} params
     * @returns
     */
    static roleList(params) {
        return request({
            url: '/hawksystemserver/role/getAllRole',
            method: 'get',
            params,
        })
    }
    /**
     * 用户注册
     * @param {*} data 
     * @returns 
     */
    static userAdd(data) {
        return request({
            url: '/hawksystemserver/user/registerUser',
            method: 'post',
            data,
        })
    }
    /**
   * 详细信息
   * @param {*} id
   */
  static userDetail(id) {
    return request({
      url: `/hawksystemserver/user/getInfoById/${id}`,
      method: 'get',
    })
  }
    /**
   * 用户修改
   * @param {*} data
   * @returns
   */
  static userEdit(data) {
    return request({
      url: '/hawksystemserver/user/modifyInfo',
      method: 'post',
      data,
    })
  }
    /**
     * 挂载无人机列表
     * @param {*} params 
     * @returns 
     */
    static mountList(params) {
        return request.get(`/hawksystemserver/device/organTree`, {
            params
        })
    }
    /**
     * 警力装备统计
     * @param {*} params 
     * @returns 
     */
    static Stat(params) {
        return request.get("/hawksystemserver/home/equipmentTotal", {
            params
        })
    }

    /**
     * 无人机列表
     * @param {*} params 
     * @returns 
     */
    static ListDrone(params) {
        return request.get("/hawksystemserver/device/deviceListWeb", {
            params
        })
    }

    /**
     * 挂载列表
     * @param {*} params 
     * @returns 
     */
    static ListMount(params) {
        return request.get("/hawksystemserver/mount/mountListAll", {
            params
        })
    }
    /**
     * 所有挂载类型
     * @param {*} 
     * @returns
     */
    static listAll() {
        return request({
            url: `/hawksystemserver/mount/getAllMountTypeList`,
            method: 'post',
        })
    }

    /**
     * 鹰巢列表
     * @param {*} params 
     * @returns 
     */
    static ListNest(params) {
        return request.get("/hawksystemserver/nest/nestListWeb", {
            params
        })
    }

    /**
     * 基站列表
     * @param {*} params 
     * @returns 
     */
    static ListBaseStation(params) {
        return request.get("/hawksystemserver/base/baseList", {
            params
        })
    }

    /**
     * 电池列表
     * @param {*} params 
     * @returns 
     */
    static batteryList(params) {
        return request.get("/hawksystemserver/battery/selectLikeListWeb", {
            params
        })
    }

    /**
     * 无人机数据统计
     * @param {*} params 
     * @returns 
     */
    static FlightInfo(params) {
        return request.get("/hawksystemserver/home/dataTotal", {
            params
        })
    }

    /**
     * 无人机和任务统计
     * @param {*} params 
     * @returns 
     */
    static StatusInfo(params) {
        return request.get("/hawksystemserver/home/planeTaskTotal", {
            params
        })
    }

    /**
     * 设备状态统计
     * @param {*} params 
     * @returns 
     */
    static StateInfo(params) {
        return request.get("/hawksystemserver/statistics/deviceStatusTotal", {
            params
        })
    }

    /**
     * 设备出入库统计
     * @param {*} params 
     * @returns 
     */
    static LibraryInfo(params) {
        return request.get("/hawksystemserver/statistics/storeTotal", {
            params
        })
    }


    /**
     * 基站列表
     * @param {*} params 
     * @returns 
     */
    static BaseStation(params) {
        return request.get("/hawksystemserver/base/web_baseList", {
            params
        })
    }
    /**
     * 弹窗-无人机列表
     * @param {*} params 
     * @returns 
     */
    static PopupListDrone(params) {
        return request.get("/hawksystemserver/statistics/deviceList", {
            params
        })
    }

    /**
     * 弹窗-无人机柱状图数据
     * @param {*} params 
     * @returns 
     */
    static PopupDroneChartInfo(params) {
        return request.get("/hawksystemserver/statistics/deviceCount", {
            params
        })
    }

    /**
     * 弹窗-无人机饼图数据
     * @param {*} params 
     * @returns 
     */
     static PopupDroneChart(params) {
        return request.get("/hawksystemserver/firm/getFirmCountByOrganId", {
            params
        })
    }

    /**
     * 弹窗-挂载列表
     * @param {*} params 
     * @returns 
     */
    static PopupListMount(params) {
        return request.get("/hawksystemserver/statistics/mount", {
            params
        })
    }

    /**
     * 弹窗-挂载柱状图数据
     * @param {*} params 
     * @returns 
     */
    static PopupMountChartInfo(params) {
        return request.get("/hawksystemserver/statistics/mountCount", {
            params
        })
    }

    /**
     * 弹窗-鹰巢列表
     * @param {*} params 
     * @returns 
     */
    static PopupListNest(params) {
        return request.get("/hawksystemserver/statistics/nestList", {
            params
        })
    }

    /**
     * 弹窗-鹰巢饼状图数据
     * @param {*} params 
     * @returns 
     */
    static PopupNestChartInfo(params) {
        return request.get("/hawksystemserver/statistics/nestCount", {
            params
        })
    }

    /**
     * 弹窗-电池列表
     * @param {*} params 
     * @returns 
     */
    static PopupListBattery(params) {
        return request.get("/hawksystemserver/statistics/battery", {
            params
        })
    }

    /**
     * 弹窗-单位下拉列表
     * @param {*} params 
     * @returns 
     */
    static GetPopupUnitList(params) {
        return request.get("/hawksystemserver/statistics/getUnitList", {
            params
        })
    }
    /**
     * 弹窗-单位下拉列表
     * @param {*} params 
     * @returns 
     */
     static getAllOrganList(params) {
        return request.get("/hawksystemserver/firm/getAllOrganList", {
            params
        })
    }
    /**
     * 通知
     * @param {*} params
     * @returns
     */
    static flowSwitch(data) {
        return request.post(
            `/hawksystemserver/device/flowSwitch?deviceHardId=${data.deviceHardId}&udpStatus=${data.udpStatus}`
        )
    }
    // /hawksystemserver/ground-station/task-update

    /**
     * 修改任务状态
     * @param {*} params
     * @returns
     */
    static task_update(data) {
        return request.post(
            '/hawksystemserver/ground-station/task-update',
            data
        )
    }

    /**
     * 无人机、鹰巢工作状态统计弹窗
     * @param {*} params
     * @returns
     */
    static DroneOrNestPopupInfo(params) {
        return request.get(
            '/hawksystemserver/deviceManagement/getDeviceOrNestInfo', {
                params
            }
        )
    }

    /**
     * 无人机飞行统计弹窗
     * @param {*} params
     * @returns
     */
    static FlightStatisticsPopup(params) {
        return request.get(
            '/hawksystemserver/home/getAllFlightNumDesc', {
                params
            }
        )
    }
    /**
     *  根据无人机id获取当天记录状态接口
     * @param {*} params
     * @returns
     */
    static getUavTakeoffCheckListStatus(params) {
        return request.get(
            '/hawksystemserver/uavTakeoffChecklist/getUavTakeoffCheckListStatus', {
                params
            }
        )
    }

    /**
     * 航线交叉
     * @param {*} params
     * @returns
     */
    static checkNoFlyFlightSpece(params) {
        return request.get(
            `/hawksystemserver/flight/checkNoFlyFlightSpece/${params.taskId}`
        )
    }


    /** 航线交叉 v1
     * @param {*} params
     * @returns
     */
    static checkFlightLine(params) {
        return request.get(
            `/hawksystemserver/flight/checkFlightLine/${params.taskId}`
        )
    }
    /** 航线交叉 v2
     * @param {*} params
     * @returns
     */
    static checkBeforeTakeOff(params) {
        return request.get(
            `/hawksystemserver/device_waring/checkBeforeTakeOff/${params.taskId}`
        )
    }
    /** 生成架次 
     * @param {*} params
     * @returns
     */
    static getFlightSortic(params) {
        return request.get(
            `/hawksystemserver/ground-station/getFlightSortic/${params.taskId}/${params.deviceHardId}`
        )
    }
    /**
   * 基站注册
   * @param {*} data
   * @returns
   */
  static baseAdd(data) {
    return request({
      url: '/hawksystemserver/base/insBase',
      method: 'post',
      data,
    })
    }
    /**
   * 基站修改
   * @param {*} data
   * @returns
   */
  static baseEdit(data) {
    return request({
      url: '/hawksystemserver/base/updBase',
      method: 'post',
      data,
    })
  }
    /**
   * 详细信息
   * @param {*} id
   */
  static baseDetail(id) {
    return request({
      url: `/hawksystemserver/base/baseInfo/${id}`,
      method: 'get',
    })
    }
    /**
   * 修改基站状态
   * @param {*} data
   * @returns
   */
  static baseEditStatus(data) {
    return request({
      url: '/hawksystemserver/base/updBase',
      method: 'post',
      data,
    })
  }
    /**
   * 创建电池
   * @param {*} data
   * @returns
   */
  static battAdd(data) {
    return request({
      url: '/hawksystemserver/battery/create',
      method: 'post',
      data,
    })
    }
    /**
   * 挂载新增
   * @param {*} data
   * @returns
   */
  static mountAdd(data) {
    return request({
      url: '/hawksystemserver/mount/insMount',
      method: 'post',
      data,
    })
    }
    /**
   * 详细信息
   * @param {*} id
   */
  static mountDetail(id) {
    return request({
      url: `/hawksystemserver/mount/findMount/${id}`,
      method: 'get',
    })
  }
    /**
   * 挂载修改
   * @param {*} data
   * @returns
   */
  static mountEdit(data) {
    return request({
      url: '/hawksystemserver/mount/updMount',
      method: 'post',
      data,
    })
    }
    /**
   * 改变挂载状态
   * @param {*} id
   * @returns
   */
  static mountEditStatus(id) {
    return request({
      url: `/hawksystemserver/mount/delMount/${id}`,
      method: 'post',
    })
  }
     /**
   * 鹰巢注册
   * @param {*} data
   * @returns
   */
  static nestAdd(data) {
    return request({
      url: '/hawksystemserver/nest/insNest',
      method: 'post',
      data,
    })
    }
    /**
   * 鹰巢修改
   * @param {*} data
   * @returns
   */
  static nestEdit(data) {
    return request({
      url: '/hawksystemserver/nest/updNest',
      method: 'post',
      data,
    })
  }
    /**
   * 详细信息
   * @param {*} id
   */
  static nestDetail(id) {
    return request({
      url: `/hawksystemserver/nest/nestInfo/${id}`,
      method: 'get',
    })
    }
    /**
   * 改变鹰巢状态
   * @param {*} id
   * @returns
   */
  static nestEditStatus(id) {
    return request({
      url: `/hawksystemserver/nest/delNest?id=${id}`,
      method: 'get',
    })
    }
    /**
   * 厂商
   * @param {*} 
   * @returns
   */
  static getFirmNameList() {
    return request({
      url: `/hawksystemserver/firm/getFirmNameList`,
      method: 'get',
    })
    }
    /**
   * 厂商
   * @param {*} params
   * @returns
   */
  static getGoodsNameListByFirm(params) {
    return request({
      url: `/hawksystemserver/firm/getGoodsNameListByFirm`,
        method: 'get',
        params
    })
  }

  static getGoodsById(params) {
    return request({
      url: `/hawksystemserver/firm/getGoodsById/${id}`,
        method: 'get',
        params
    })
  }

   /**
   * 维保记录列表
   * @param {*} params
   * @returns
   */
    static getWbList(params) {
        return request({
          url: `/maintenanceserver/maintenance/page`,
            method: 'get',
            params
        })
    }
       /**
   * 维保记录详情
   * @param {*} id
   * @returns
   */
        static getWbDetail(id) {
            return request({
              url: `/maintenanceserver/maintenance/view/${id}`,
                method: 'get',
            })
    }
    /**
   * 维保记录详情
   * @param {*} params
   * @returns
   */
     static RecordExport(params) {
        return request({
            url: `/maintenanceserver/maintenance/export-word`,
            method: 'get',
            params,
            responseType: "blob"
        })
}
}