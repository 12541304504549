import request from "../request"

export default class Factor {
    static List(params) {
        /**
         * 1、主要设备
         * 2、警务元素
         * 3、前端感知设备
         * 4、警力元素
         * 5、135 快返圈
         * 6、重点地区
         */
        let cate_map = {
            1: "/hawksystemserver/home/main_equipment",
            2: "/hawksystemserver/home/jing-wu-yuan-su",
            3: "/hawkzhongtaiserver/zt-sensing/queryDeviceList",
            4: "/hawkzhongtaiserver/zt-police/queryPoliceList",
            5: "/hawkzhongtaiserver/zt-kfq/querylist",
            6: "/hawksystemserver/home/external_key_place"
        }
        let { cate = 1, ...rest } = params

        return request.get(cate_map[cate], {
            params: rest,
            qs: true
        })
    }
}