export default {
  namespaced: true,

  state: {
    // 
    situation_detail: {},
    situation_is_show: true,
    // 判断是否从警情派发跳到航线规划页面
    is_from_situation_edit: false
  },

  mutations: {
    SITUATION_DETAIL: (state, data) => {
      state.situation_detail = data
    },
    IS_FROM_SITUATION_EDIT: (state, data) => {
      state.is_from_situation_edit = data
    },

    SITUATION_IS_SHOW: (state, data) => {
      state.situation_is_show = data
    },
  },

  actions: {},
}
