var g_data = {
  viewer: false
}

import Vue from 'vue'
// 定义属性存储
var dateTime_value = new Date()

/*  父子组件事件触发搬运车 */
var eventBus = new Vue()
Vue.prototype.g_bus = eventBus

/* 定义每个属性的监听事件 */
const keys = Object.keys(g_data)
keys.forEach(objItem => {
  Object.defineProperty(g_data, objItem, {
    get: function () {
      return complete_view
    },
    set: function (newValue) {
      dateTime_value = newValue
      eventBus.$emit(`watch_${objItem}`, newValue)
    }
  })
})

export default g_data


let primitives_3d_tiles = {}
let global_leyers = []
let base_layer = {

}
let jichang = [[
  [120.07244929263835, 33.32582582119295],
  [120.11399134586101, 33.29785183039257],
  [120.1650239166702, 33.37094442676202],
  [120.17652522892605, 33.36693028114779],
  [120.1924897369827, 33.364062920883775],
  [120.20227443546902, 33.364062920883775],
  [120.2173806366409, 33.366356816654594],
  [120.23025523991238, 33.3712311443612],
  [120.24381648869168, 33.37940219865585],
  [120.2529145416702, 33.3881458098736],
  [120.26235591740262, 33.3980350643141],
  [120.27025234074246, 33.40649020101464],
  [120.27797710270535, 33.418240041408346],
  [120.28123866886746, 33.42783934065973],
  [120.28226863712918, 33.43686457912875],
  [120.28175365299832, 33.44674828714271],
  [120.27797710270535, 33.457490170792816],
  [120.27351390690457, 33.466225921579856],
  [120.26956569523465, 33.47095145259896],
  [120.26561748356474, 33.47510397926842],
  [120.3399835612254, 33.53228834000533],
  [120.29844150800274, 33.55997231858508],
  [120.24724971623074, 33.487274031639814],
  [120.23815166325222, 33.49099629439919],
  [120.23145686955107, 33.49271420786418],
  [120.22064220280302, 33.49428893193307],
  [120.20622264713894, 33.494145776383],
  [120.19712459416043, 33.492571049710534],
  [120.18888484806669, 33.490137324885936],
  [120.17944347233427, 33.4862718566385],
  [120.17103206486357, 33.48111763060119],
  [120.16347896427762, 33.4741016634643],
  [120.15197765202176, 33.46207296950218],
  [120.14356624455105, 33.45348002320971],
  [120.13824474186552, 33.446318584122],
  [120.1349831757034, 33.44101873851076],
  [120.13292323917996, 33.43643482715134],
  [120.13154994816433, 33.431564155993264],
  [120.13051997990262, 33.42497398932464],
  [120.13017665714871, 33.4198161185855],
  [120.13069164127957, 33.411792155070074],
  [120.13343822331082, 33.403624145164294],
  [120.13704311222683, 33.39574200407128],
  [120.1404763397659, 33.390152416254764],
  [120.14682781071316, 33.38312909128191],
  [120.07244929263835, 33.32582582119295],
]]

let paodao = [[
  [120.17657419045464, 33.40549730481285],
  [120.18494268258111, 33.39983668878474],
  [120.23601194222466, 33.45259508271359],
  [120.22751470406548, 33.45828806294143],
  [120.17657419045464, 33.40549730481285],
]]

export {
  primitives_3d_tiles,
  global_leyers,
  base_layer,
  jichang,
  paodao,
}
