import request from '../request'

export default class TableExport {
  /**
   * 飞行统计
   * @param {*} params
   * @returns
   */
  static dataTotal(params) {
    return request.get('/hawksystemserver/bigScreen/dataTotal', {
      params,
    })
  }

  /**
   * 人员统计
   * @param {*} params
   * @returns
   */
  static getPersonnelStatistics(params) {
    return request.get('/hawksystemserver/personnelStatistics/getPersonnelStatistics', {
      params,
    })
  }

  /**
   * 人员导出
   * @param {*} params
   * @returns
   */
  static exportPersonnelStatistics(params) {
    return request.get('/hawksystemserver/personnelStatistics/exportPersonnelStatistics', {
      params,
    })
  }

  /**
   * 警情统计
   * @param {*} params
   * @returns
   */
  static statisticsPoliceTaskStatusNumber(params) {
    return request.get('/hawksystemserver/bigScreen/statisticsPoliceTaskStatusNumber', {
      params,
    })
  }

  /**
   * 设备统计
   * @param {*} params
   * @returns
   */
  static getDeviceStatistics(params) {
    return request.get('/hawksystemserver/personnelStatistics/getDeviceStatistics', {
      params,
    })
  }

  /**
   * 一键通知
   * @param {*} params
   * @returns
   */
  static Inform(data) {
    return request.post(
      '/hawksystemserver/person-management/oneNotification',
      data
    )
  }

  /**
   * 飞行助手-警员报备
   * @param {*} params
   * @returns
   */
   static Check(params) {
    return request.get(
      '/hawksystemserver/person-management/cardInfos_v2',
      {params}
    )
  }
  /**
   * 飞行助手-警员报备
   * @param {*} params
   * @returns
   */
   static getechartData(params) {
    return request.get(
      '/hawksystemserver/person-management/flyingCounts',
      {params}
    )
  }
  /**
   * 人员管理-任务详情
   * @param {*} params
   * @returns
   */
   static getTaskdetail(params) {
    return request.get(
      '/hawksystemserver/person-management/getPerformanceRankById',
      {params}
    )
  }
}
