import { Message } from 'element-ui'

/**
 * 单例 message
 */
let messageInstance = null;
let message = function DoneMessage(options) {
    //如果弹窗已存在先关闭
    if (messageInstance) {
        messageInstance.close();
    }
    messageInstance = Message(options);
}
let arr = ['success', 'warning', 'info', 'error'];
arr.forEach(function(type) {
    message[type] = function(options) {
        if (typeof options === 'string') {
            options = {
                message: options
            };
        }
        options.type = type;
        return message(options);
    };
});

export {
    message
}