// 大屏展示接口 // 位置 '@/pages/boss/index.vue'
import request from '../request'

export default class Person {
    /**
  * 无人机执行任务总量
  * @param {*} params
  * @returns
  */
    static renwu(params) {
        return request.get('/hawksystemserver/bigScreen/getDeviceTaskCount', {
            params,
        })
    }


    /**
    * 年度无人机飞行里程接口
    * @param {*} params
    * @returns
    */
    static zonglicheng(params) {
        return request.get('/hawksystemserver/bigScreen/getFlightDistanceByYear', {
            params,
        })
    }


    /**
* 总架次、总里程、总时长、总任务
* @param {*} params
* @returns
*/
    static zongshuju(params) {
        return request.get('/hawksystemserver/home/dataTotal?type=3&organId=1', {
            params,
        })
    }

    /**
* 年度无人机飞行架次接口
* @param {*} params
* @returns
*/
    static zjc(params) {
        return request.get('/hawksystemserver/bigScreen/getFlightSortieByYear', {
            params,
        })
    }

    /**
* 当前任务
* @param {*} params
* @returns
*/
    static dangqianrenwu(params) {
        return request.get('/hawksystemserver/bigScreen/taskList', {
            params,
        })
    }

    /**
* 人员统计
* @param {*} params
* @returns
*/
    static renyuantongji(params) {
        return request.get('/hawksystemserver/bigScreen/personTotal', {
            params,
        })
    }

    /**
* 设备统计
* @param {*} params
* @returns
*/
    static shebeitj(params) {
        return request.get('/hawksystemserver/statistics/deviceStatusTotal', {
            params,
        })
    }

    /**
* 地图选择按钮
* @param {*} params
* @returns
*/
    static cesiumBtn(params) {
        return request.get('/hawksystemserver/statistics/deviceStatusTotal', {
            params,
        })
    }
}
