import axios from "axios"

const key = "8b6fc51c10974b719fb4e032511fe102";
const appKey = "30c6030f6e144f08b51f7781699276c3"

let isSwtxDev = (process.env.VUE_APP_SWTX_ENV === "development")

export default class Map {

    static Regeo(params) {

        return new Promise((resolve, reject) => {
            if (isSwtxDev) {
                // 开发者模式
                axios.get("https://minedata.cn/service/lbs/reverse/v1/regeo", {
                    params: {
                        key,
                        ...params
                    }
                }).then(res => {
                    if (res.data && res.data.regeocodes && res.data.regeocodes.length) {
                        resolve(res.data.regeocodes[0].formatted_address)
                    } else {
                        reject(null)
                    }
                }).catch(e => reject(e))
            } else {
                // 生产模式
                axios.get("http://32.128.12.138:21009/service/lbs/coder/reverse-geocoding", {
                    params: {
                        appKey,
                        ...params
                    }
                }).then(res => {
                    if (res.data && res.data.data && res.data.data.poi) {
                        resolve(res.data.data.poi)
                    } else {
                        reject(null)
                    }
                }).catch(e => reject(e))
            }
        })
    }

    static AiRegeo(params) {

        return new Promise((resolve, reject) => {
            if (isSwtxDev) {
                // 开发者模式
                axios.get("https://minedata.cn/service/lbs/reverse/v1/regeo", {
                    params: {
                        key,
                        ...params
                    }
                }).then(res => {
                    if (res.data && res.data.regeocodes && res.data.regeocodes.length) {
                        resolve(res.data.regeocodes[0].formatted_address)
                    } else {
                        reject(null)
                    }
                }).catch(e => reject(e))
            } else {
                // 生产模式
                axios.get("http://32.128.12.138:21009/service/lbs/coder/reverse-geocoding", {
                    params: {
                        appKey,
                        ...params
                    }
                }).then(res => {
                    if (res.data && res.data.data && res.data.data.poi) {
                        resolve(res.data.data)
                    } else {
                        reject(null)
                    }
                }).catch(e => reject(e))
            }
        })
    }

    static Geo(params) {
         let promise = isSwtxDev
          ? axios.get("https://service.minedata.cn/service/lbs/search/v1/geo", {
              params: {
                key,
                address: params.keywords
              },
            }).then(res => {
              res.data.data = {
                rows: []
              };
              if(res.data.count > 0){
                res.data.data.rows = res.data.geocodes;
                res.data.data.rows.forEach((item) => {
                  item.address = item.formatted_address;
                  item.name = "";
                });
              }else{
                res.data.data.rows = [];
              }
              
              return res;
            })
          : axios.get(
              "http://32.128.12.138:21009/service/lbs/coder/geocoding2",
              {
                params: {
                  appKey,
                  city: "全国",
                  page_size: 20,
                  ...params,
                },
              }
            ); 
        // let promise = axios.get("https://service.minedata.cn/service/lbs/search/v1/geo", {
        //   params: {
        //     key,
        //     address: params.keywords,
        //   },
        // })
        // .then((res) => {
        //   res.data.data = {
        //     rows: [],
        //   };
        //   if (res.data.count > 0) {
        //     res.data.data.rows = res.data.geocodes;
        //     res.data.data.rows.forEach((item) => {
        //       item.address = item.formatted_address;
        //       item.name = "";
        //     });
        //   } else {
        //     res.data.data.rows = [];
        //   }

        //   return res;
        // });
        return promise
    }

    static Driv(params) {
        let promise = axios.get("http://32.128.12.138:21009/service/lbs/coder/geocoding2", {
            params: {
                appKey,
                city: "全国",
                page_size: 20,
                ...params
            }
        })
        return promise
    }
}