import request from "../request"

export default class Mapmanage {

    /**
     * 获取地图列表
     * @param {*} data 
     * @returns 
     */
    static GetLayerList(params){
        return request.get('/hawksystemserver/serviceData/web_serverDataList', {
            params
        })
    }

    /**
     * 获取标注列表
     * @param {*} data 
     * @returns 
     */
    static GetLabelList(params){
        return request.get('/hawksystemserver/marker/get-list', {
            params
        })
    }

    /**
     * 修改标注
     * @param {*} data 
     * @returns 
     */
    static UpMarker(data){
        return request.post('/hawksystemserver/marker/upMarker', data)
    }

    /**
     * 添加标注
     * @param {*} data 
     * @returns 
     */
    static AddMarker(data){
        return request.post('/hawksystemserver/marker/setmarker', data)
    }

    /**
     * 删除标注
     * @param {*} data 
     * @returns 
     */
    static DropMarker(params){
        return request.get(`/hawksystemserver/marker/dropMarker`, {
            params
        })
    }  
    
    /**
     * 获取标签分页列表
     * @param {*} data 
     * @returns 
     */
    static GetTagList(params){
        return request.get('/hawksystemserver/tag/tagPageList', {
            params
        })
    }

    /**
     * 获取标签列表
     * @param {*} data 
     * @returns 
     */
    static GetTags(params){
        return request.get('/hawksystemserver/tag/list', {
            params
        })
    }

    /**
     * 添加标签
     * @param {*} data 
     * @returns 
     */
    static AddTag(data){
        return request.post('/hawksystemserver/tag/add', data)
    }

    /**
     * 编辑标签
     * @param {*} data 
     * @returns 
     */
    static EditTag(data){
        return request.post('/hawksystemserver/tag/edit', data)
    }

    /**
     * 删除标签
     * @param {*} data 
     * @returns 
     */
    static DropTag(params){
        return request.get(`/hawksystemserver/tag/drop/${params.id}`, {
            params
        })
    }  

    /**
     * 上传文件
     * @param {*} data 
     * @returns 
     */
    static fileUpload(data){
        // return request.post('/jhmarkerservice/file/upload', data)
        return request.post(`upload/v2_uploads`, data)
    }

    /**
     * 船只识别
     * @param {*} data 
     * @returns 
     */
    static boatUpload(data){
        return request.post(`https://tmj.mmcuav.cn/ai_ability/detect/image`, data)
    }

    /**
     * 导出报告
     * @param {*} data 
     * @returns 
     */
    static exportWordPreview(params){
        return request.get(`/hawksystemserver/account/exportword`, {
            params
        })
    }
}