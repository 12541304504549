export default {
  namespaced: true,

  state: {
    flight_detail: {},
    controlPanelData: {}
  },

  mutations: {
    FLIGHT_DETAIL: (state, data) => {
      state.flight_detail = data
    },
    // 控制面板航线数据
    SET_FlLIGHTN_DETAIL: (state, data) => {
      state.controlPanelData = data
    },
  },

  actions: {},
}