import request from "../request"
import http from "../http"
import axios from 'axios'
/**
 * 航线
 */
export default class Airway {
  static GetAirwayInfo(id) {
    return http.get(`/hawksystemserver/airRoutes/transform2broker/${id}`);
  }
  // 航线转换
  static GetAirwayInfo(id) {
    return http.get(`/hawksystemserver/airRoutes/transform2broker/${id}`);
  }
    // 查看飞机执行中的航线
    static getNestTaskInProgress(id) {
      return http.get(`/hawksystemserver/eagleNest/getNestTaskInProgress/${id}`);
    }
  /**
   * 航线标签
   * @param {*} params
   * @returns
   */
  static update(params) {
    return http.post("/hawksystemserver/flightLineLabel/update", params);
  }

  /**
   * 航线标签
   * @param {*} params
   * @returns
   */
  static insert(params) {
    return http.post("/hawksystemserver/flightLineLabel/insert", params);
  }

  /**
   * 下拉列表 鹰巢
   * @param {*} params
   * @returns
   */
  static getNestListForLine(params) {
    return request.get("/hawksystemserver/eagleNest/getNestListForLine", {
      params,
    });
  }

  /**
   * 列表
   * @param {*} params
   * @returns
   */
  static getFlightLineLabel(params) {
    return request.get("/hawksystemserver/flightLineLabel/getFlightLineLabel", {
      params,
    });
  }
  /**
    /hawksystemserver/flightLineLabel/getLabelById/{id}


    /**
     * 根据航线id获取航线标签数据接口
     * @param {*} params 
     * @returns 
     */
  static getLabelById(params) {
    return request.get(
      `/hawksystemserver/flightLineLabel/getLabelById/${params.id}`
    );
  }

  /**
   * 列表
   * @param {*} params
   * @returns
   */
  static getApprovedTask(params) {
    return request.get("/hawksystemserver/flightMission/task/getApprovedTask", {
      params,
    });
  }
  /**
   * 列表
   * @param {*} params
   * @returns
   */
  static List(params) {
    return request.get("/hawksystemserver/flight/flightLineList", {
      params,
    });
  }

  /**
   * 获取航线标签
   * @param {*} params
   * @returns
   */
  static labelList() {
    return request.get("/hawksystemserver/flightLineLabel/getFlightLineLabel");
  }

  /**
   * 新增
   * @param {*} params
   * @returns
   */
  // static Add(params) {
  //     return request.post("/hawksystemserver/flight/insFlightLine", params)
  // }
  /**
   * 新增
   * @param {*} params
   * @returns
   */
  static Add(params) {
    return http.post("/hawksystemserver/flight/insFlightLine", params);
  }

  static ApplyFlightLineAuth(params) {
    return http.get("/hawksystemserver/flight/applyFlightLineAuth", {
      params,
    });
  }

  static Delete(params) {
    return request.get("/hawksystemserver/flight/deleteFlightLine", { params });
  }

  /**
   * 编辑
   * @param {*} params
   * @returns
   */
  static Edit(params) {
    return request.post("/hawksystemserver/flight/insFlightLine", params);
  }

  /**
   * 下拉
   * @param {*} params
   * @returns
   */
  static Drd(params) {
    return request.get("/hawksystemserver/flight/getFlightLineList", {
      params,
    });
  }

  /**
   * 自动航线规划//内网用
   * @param {*} params
   * @returns
   */
  //  static autoPlan(params){
  //     return axios2.get("/service/lbs/route/driving3", {
  //         params
  //     })
  // }
  // /32:128.12.138:21009

  /**
   * 改为安全状态
   * @param {*} params
   * @returns
   */
  static EditIssafe(params) {
    return request.post("/hawksystemserver/flight/editIssafe", params);
  }

  /**
   * 修改航线标签
   * @param {*} params
   * @returns
   */
  static updatatag(params) {
    return request.post(
      "/hawksystemserver/flightLineLabel/updateLabelRelation",
      params
    );
  }
  /**
   * 鹰巢任务列表
   * @param {*} params
   * @returns
   */
  static getNestApprovedTask(params) {
    return request.get(
      "/hawksystemserver/flightMission/task/getNestApprovedTask",
      {
        params,
      }
    );
  }

  /**
   * 改为安全状态
   * @param {*} params
   * @returns
   */
  static addTimingTask(params) {
    return request.post("/hawksystemserver/eagleNest/addTimingTask", params);
  }

  /**
   *
   * @param {*} params
   * @returns
   */
  static addNestAutoTask(params) {
    return request.post("/hawksystemserver/task/addNestAutoTask", params);
  }

  //迅蚁token
  static autoRouteToken =
    "MTU3MjkjMTY1MzI2OTc0MEBhbGdvci10ZXN0Lnh5aXRlY2guY29tI2trZC9pTlF5MEgwemhiQnAxZVMreE5nL01UWT0=";

  /**
   * 自动航线创建任务
   * @param {Object} params
   * @returns
   */
  static planTaskCreateTask(params) {
    return axios.post(
      `${process.env.VUE_APP_AUTO_PLAN}/plan/task/create_task?token=${this.autoRouteToken}`,
      {
        token: this.autoRouteToken,
        range_points_list: [],
        alternate_route: {
          is_enabled: true,
          routes_num: 0,
        },
        obstacles_min_safety_distance: {
          horizontal_direction: 2,
          height: 2,
        },
        ...params,
      }
    );
  }

  /**
   * 自动航线确认任务
   * @param {Object} params
   * @returns
   */
  static planTaskControl(params) {
    return axios.post(
      `${process.env.VUE_APP_AUTO_PLAN}/plan/task/control?token=${this.autoRouteToken}`,
      {
        id: params.id,
        cmd: "start",
        device_id: 100,
      }
    );
  }

  /**
   * 搜索航线
   * @param {Object} params 
   */
  static planTaskResultSearch(params){
    return axios.get(
      `${process.env.VUE_APP_AUTO_PLAN}/plan/task/result/search?token=${this.autoRouteToken}&task_id=${params.task_id}`
    );
  }
}

