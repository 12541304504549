//飞控中心相关
export default {
  namespaced: true,

  state: {
    startGrade: false,    //开启战时状态
    weather: null,//天气数据
    weatheryjFlag:false,//天气预警弹窗
    weatherList:[],//天气预警列表
  },

  mutations: {
    SET_START_GRADE(state, data) {
      state.startGrade = data;
    },
    SET_WEATHER(state,data){
      state.weather = data
    },
    SET_WEATHER_LIST(state,data){
      state.weatherList = data
    },
    SET_WEATHER_FLAG(state,data){
      state.weatheryjFlag = data
    }
  },

  actions: {},
};
