// 解决总览页各个弹框层级问题
// 层级问题
export default {
    namespaced: true,
    state: {
        Zindex: [
            { tai: true, id: 0 },//传0代表排班层级提到99
            { tai: true, id: 1 },//传1代表值班层级提到99
            { tai: true, id: 2 },//传2代表安全飞行层级提到99
            { tai: true, id: 3 },//传3代表报备情况层级提到99
            { tai: true, id: 4 },// 传4代表报表导出层级提到99
            { tai: true, id: 5 },// 传5代表飞行检查清单层级提到99
            { tai: true, id: 6 },// 传6代表消息通知层级提高到99
            { tai: true, id: 7 },// 传7代表数据在线级提高到99
            { tai: true, id: 8 },// 传8代表飞行统计级提高到99
            { tai: true, id: 9 },// 传8代表飞行统计级提高到99
            { tai: true, id: 10 },// 传8代表飞行统计级提高到99
            { tai: true, id: 11 },// 传8代表飞行统计级提高到99
            { tai: true, id: 12},// 传8代表零登入级提高到99
            { tai: true, id: 13 },// 传8代表零在线级提高到99
            { tai: true, id: 14 },// 传8代表零位移级提高到99
            { tai: true, id: 99 },// 无用
        ]
    },

    mutations: {
        ceng(state,num) {
            state.Zindex.forEach(item => {
                if (item.id == num) {
                    item.tai = false

                } else {
                    item.tai = true
                }
            })
            // console.log(state.Zindex, '成功了吗');
        }
    },

    actions: {},
}