import request from '../request'

export default class Person {
  /**
   * 飞手-指挥员信息管理
   * @param {*} params
   * @returns
   */
  static Info(params) {
    return request.get('/hawksystemserver/person-management/flying-hand-info', {
      params,
    })
  }

  /**
   * 飞手-指挥员打卡管理  弃用
   * @param {*} params
   * @returns
   */
  // static Check(params) {
  //   return request.get('/hawksystemserver/person-management/cardInfos', {
  //     params,
  //   })
  // }

  /**
   * 绩效排名
   * @param {*} params
   * @returns
   */
  static Rank(params) {
    return request.get('/hawksystemserver/person-management/performance-rank', {
      params,
    })
  }

  /**
   * 人员统计
   * @param {*} params
   * @returns
   */
  static Statistics(params) {
    return request.get('/hawksystemserver/person-management/user-count_v2', {
      params,
    })
  }

  /**
   * 打卡统计
   * @param {*} params
   * @returns
   */
  static CheckInfo(params) {
    return request.get('/hawksystemserver/person-management/cardCount', {
      params,
    })
  }

  /**
   * 一键通知
   * @param {*} params
   * @returns
   */
  static Inform(data) {
    return request.post(
      '/hawksystemserver/person-management/oneNotification',
      data
    )
  }

  /**
   * 飞行助手-警员报备
   * @param {*} params
   * @returns
   */
   static Check(params) {
    return request.get(
      '/hawksystemserver/person-management/cardInfos_v2',
      {params}
    )
  }
  /**
   * 飞行助手-警员报备
   * @param {*} params
   * @returns
   */
   static getechartData(params) {
    return request.get(
      '/hawksystemserver/person-management/flyingCounts',
      {params}
    )
  }
  /**
   * 人员管理-任务详情
   * @param {*} params
   * @returns
   */
   static getTaskdetail(params) {
    return request.get(
      '/hawksystemserver/person-management/getPerformanceRankById',
      {params}
    )
  }
}
