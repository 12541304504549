// 新总览页接口

import request from "../request";

export default class newHome {
  /**
   * 总览页手机短信通知进行打卡报备
   * @param {*} params
   * @returns
   */
  static phoneCardToOrg(params) {
    return request.post(
      "/hawksystemserver/person-management/phoneCardToOrg",
      params
    );
  }
  /**
   * 总览页安全预警信息列表
   * @param {*} params
   * @returns
   */
  static ist() {
    return request.get("/hawksystemserver/device_waring/home/list");
  }
  /**
   * 总览页修改预警信息
   * @param {*} params
   * @returns
   */
  static update(params) {
    return request.put("/hawksystemserver/device_waring/update", params);
  }
  /**
   * 总览页手机预警信息
   * @param {*} data
   * @returns
   */
  static message(data) {
    return request.post("/hawksystemserver/device_waring/sendNotice", data);
  }
  /**
   * 总览页各单位根据传参排名(flyNum：飞行次数，distance：飞行里程，duration：时长)
   * @param {*} params
   * @returns
   */
  static getAllFlightKm(params) {
    return request.get("/hawksystemserver/home/getAllFlightKm", {
      params,
    });
  }
  /**
   * 总览页机构累计警情任务数排序
   * @param {*} params
   * @returns
   */
  static getAllAlarmNumOrderBy(params) {
    return request.get("/hawksystemserver/home/getAllAlarmNumOrderBy", {
      params,
    });
  }
  /**
   * 总览页-问题反馈-问题类型列表
   * @param {*} params
   * @returns
   */
  static typeList() {
    return request.get("/hawksystemserver/question/typeList");
  }
  /**
   * 总览页-问题反馈-问题上报
   * @param {*} params
   * @returns
   */
  static add(params) {
    return request.post("/hawksystemserver/question/add", params);
  }
  /**
   * 总览页-勤务报备
   * @param {*} params
   * @returns
   */
  static orgOrder(params) {
    return request.get("/hawksystemserver/person-management/getCardInfosOrgOrder",{params});
  }
}
