const _ = require('lodash')
const ctx = require.context('./modules', true, /\.js$/)
let modules = ctx.keys().reduce((modules, key) => {
    // ./app.js => app
    let name = key.replace(/^\.\/(.*)\.js$/, '$1'),
        value = ctx(key).default
    modules[_.toUpper(name)] = value
    return modules
}, {})

export default modules
