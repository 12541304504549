import request from '../request'


export default class Tone {

  
  static getuavlist(id) {
    return request.get(`/hawksystemserver/mount/uavList?organId=${id}`)
  }
  /**
   *  点调列表
   * @param {*} params
   * @returns
   */
  static getSettingPage(params) {
    return request.get('/hawksystemserver/dispatch/getSettingPage', {
      params: params
    })
  }

  /**
   * 子点调
   * @param {*} params
   * @returns
   */
  static getItemPageBySettingId(params) {
    return request.get('/hawksystemserver/dispatch/getItemPageBySettingId', {
      params: params
    })
  }

  /**
   * 子点调查询各单位点调
   * @param {*} params
   * @returns
   */
  static getItemInfoListById(params) {
    return request.get('/hawksystemserver/dispatch/getItemInfoListById', {
      params: params
    })
  }

  /**
   * 创建点调
   * @param {*} data
   * @returns
   */
  static insertDispatch(data) {
    return request.post('/hawksystemserver/dispatch/insertDispatch', data)
  }

  /**
   * 编辑点调
   * @param {*} data
   * @returns
   */
  static update(data) {
    return request.put('/hawksystemserver/dispatch/update',
      data
    )
  }

  /**
   * 点调详情
   * @param {*} id
   * @returns
   */
  static detail(id) {
    return request.get(`/hawksystemserver/dispatch/getDispatch/${id}`)
  }

  /**
   * 点调删除
   * @param {*} id
   * @returns
   */
  static delete(id) {
    return request.delete(`/hawksystemserver/dispatch/delete/${id}`)
  }

  /**
   *发布点调
   * @param {*} params
   * @returns
   */
  static release(params) {
    return request.get('/hawksystemserver/dispatch/release', {
      params: params
    })
  }

  /**
   * 临时点调单位
   * @param {*} params
   * @returns
   */
  static listDispatchOrgane(params) {
    return request.get('/hawksystemserver/dispatch/listDispatchOrgan', {
      params: params
    })
  }

  /**
   * 开始点调
   * @param {*} data
   * @returns
   */
  static startDispatch(data) {
    return request.put('/hawksystemserver/dispatch/startDispatch', 
      data
    )
  }

  /**
   * 修改点调信息
   * @param {*} data
   * @returns
   */
  static updateFeedback(data) {
    return request.post('/hawksystemserver/dispatch/updateFeedback',
      data
    )
  }

}