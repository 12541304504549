export default {
    namespaced: true,
    state: {
        deviceInfo: {},
        weather:"",
        line:{
            destination:"",
            taskTitle: "",
            cateId:""
        }
    },
    mutations: {
        SET_DEVICEINFO: (state, data) => {
            state.deviceInfo = data
        },
        SET_WEATHER: (state, data) => {
            state.weather = data
        },
        SET_LINE: (state, data) => {
            state.line = data
        }
    },

    actions: {},
}
