//针对地图黑屏问题, 保存数据与恢复, 为了简化处理, 由本js全程托管
//两种情况
//1. webgl终止, 只能刷新解决
//2. Cesium.Viewer终止, 只需要重新初始化Cesium.Viewer (不做此情况处理, Cesium.Viewer终止, 大概率webgl也终止了, 不做两套逻辑)
//3. store 的保存恢复已实现, 但未开启使用
import store from "@/store";
import Bus from "@/assets/ligature";
import Vue from 'vue';

let showAlert = false;      //是否显示alert
let showConsole = false;     //是否显示console
let isReload = false;      //是否需要刷新的标记
let viewerList = [];    //Cesium.Viewer实例队列, 结构为 {viewer: Cesium.Viewer实例, initFunc: 初始化方法}
const excludeHash = [
  "#/fckernel", //鹰视-飞控中心
  "#/observe/home", //鹰视-首页
  "#/observe", //鹰视-其它所有
];   //排除的url hash列表

function alert(str){
  if(showAlert){
    window.alert(str);
  }
}

const console = {
  info(...args){
    if(showConsole){
      let _args = [Date.now()].concat(args);
      window.console.info.apply(window.console, _args);
    }
  }
}

/* window.addEventListener("load", function() {
  console.info("readystart");
  //创建一个看不见的canvas用于监测webgl状态
  let canvas = document.createElement("canvas");
  canvas.style.position = "fixed";
  canvas.style.left = "-1000px";
  canvas.style.top = "-1000px";
  canvas.style.opacity = 0;
  document.body.append(canvas);
  console.info('ready', canvas)
  //webgl丢失事件
  canvas.addEventListener("webglcontextlost", onWebglcontextlost, false);
  //webgl恢复事件
  canvas.addEventListener(
    "webglcontextrestored",
    onWebglcontextrestored,
    false
  );
}); */
handlerCanvasError();

document.addEventListener('visibilitychange', () => {
  // console.info("visibilitychange", document.hidden, viewerList);
  if (document.hidden) {
    viewerList.forEach((item) => {
      item.viewer && item.viewer.destroy();
      item.viewer = null;
      console.info("销毁Cesium.Viewer");
    });
    return;
  }else{
    //当切换回当前页面时, 才执行刷新
   /*  if (isReload) {
      reload();
    } */

    // alert("恢复Cesium.Viewer");
    console.info("恢复Cesium.Viewer", viewerList);
    let funcList = viewerList.map(item => {
      return item.initFunc;
    })
    viewerList = [];
    funcList.forEach(func => {
      func();
    })
  }
})
/**
 * 处理canvas错误
 */
function handlerCanvasError(canvas) {
  //每X秒检查webgl是否停止工作
  let handle = setInterval(() => {
    //当前tab并未显示, 直接退出
    /* if(document.hidden){
      return;
    }
    let gl_lose = canvas
      ?.getContext("webgl")
      //?.getExtension("WEBGL_lose_context");
      WebGLRenderingContext.isContextLost();
    if (!gl_lose) {
      //取不到gl, 说明webgl已经停止工作, 直接刷新
      alert("webgl已经崩溃");
      //保存store的所有状态
      save();
      //将webgl崩溃的事件广播出去, 让各个组件自已做恢复
      Bus.$emit("webglStop");

      //无法得知其它组件保存数据需要多少时间, 固定一个时间后刷新
      setTimeout(() => {
        alert('即将刷新');
        clearInterval(handle);
        reload();
      }, 500);
    } */

    let errEl = document.querySelector(`.cesium-widget-errorPanel`);
    //错误提示的元素被创建, 说明cesium已经停止渲染
    if (errEl) {
      console.info("Cesium已停止渲染");
      alert("Cesium已停止渲染");
      //将webgl崩溃的事件广播出去, 让各个组件自已做恢复
      Bus.$emit("webglStop");
      errorAlert();
      clearInterval(handle);
    }
  }, 2000);
  
}

/**
 * Cesium.Viewer的停止监控
 * @param {*} viewer Cesium.Viewer实例
 * @param {*} initFunc 崩溃后的重新初始化方法
 */
export function cesiumViewerStopMonitor(viewer, initFunc) {
  console.info("cesiumViewerStopMonitor", window.location.hash, viewer);

  let find = excludeHash.find(hash => {
    return window.location.hash.includes(hash);
  })

  //部分页面不销毁Cesium.Viewer实例
  if (find) {
    alert("当前hash被排除");
    return;
  }
    /* viewerList.push({
      viewer,
      initFunc,
    });
  if (document.hidden) {
    //一种情况, 页面未加载完, 就切换到别的页面, 这时候先销毁实例
    viewerList.forEach((item) => {
      item.viewer && item.viewer.destroy();
      item.viewer = null;
    })
  } */
}

/**
 * 刷新
 */
function reload() {
  if (document.hidden) {
    isReload = true;
  }else{
    window.location.reload(true);
    // debugger
    // history.go(0);
  }
}
function onWebglcontextlost(e) {
  console.info("webgl丢失事件", e);
  alert("webgl丢失事件");
  reload();
}
function onWebglcontextrestored(e) {
  console.info("webgl恢复事件", e);
  /* let gl = window.viewer?.canvas.getContext('webgl');
        
        console.info('webgl恢复次数: ', initCount + 1, gl);
        if(!gl || ++initCount >= 5){
          //恢复5次失败, 重新刷新页面, 避免无限循环
          window.location.reload();
        }else{
          //恢复webgl, 销毁viwer, 重新初始化
          gl.getExtension('WEBGL_lose_context').restoreContext();
          window.viewer.canvas.removeEventListener('webglcontextlost', onWebglcontextlost);
          window.viewer.canvas.removeEventListener('webglcontextrestored', onWebglcontextrestored)
          window.viewer.destory();
          init();
        } */
}

/**
 * 保存数据
 */
export function save() {
  console.info("store", store);
  //保存state数据
  Object.keys(store.state).forEach((key) => {
    //获取每个state的值
    let item = store.state[key];
    try {
      let str = JSON.stringify(item);
      localStorage.setItem(`recover_${key}`, str);
    } catch (e) {
      console.error(`保存state失败, key为${key}`, item);
    }
  });
}

/**
 * 清空数据
 */
export function clear() {
  Object.keys(store.state).forEach((key) => {
    localStorage.removeItem(`recover_${key}`);
  });
}

/**
 * 恢复数据
 */
export function recover() {
  Object.keys(store.state).forEach((key) => {
    //获取每个state的值
    try {
      let str = localStorage.getItem(`recover_${key}`);
      let state = JSON.parse(str);

      Object.keys(state).forEach((key1) => {
        store.commit(`${key}/SET_STATE`, {
          key: key1,
          value: state[key1],
        });
      });
    } catch (e) {
      console.error(`恢复state失败, key为${key}`);
    }
  });
}

function errorAlert(){
    Vue.prototype.$el_confirm(
      "由于浏览器压力过大，地图停止展示，请手动刷新，若未解决，请重启浏览器。",
      () => {
        reload();
      },
      () => {},
      {
        // showCancelButton: false
        // cancelButtonText: "关闭",
        // confirmButtonText: "立即刷新",
      }
    );
  }

//用于调试器手动调用方法测试
window.$recover = {
  save,
  clear,
  recover,
  errorAlert
}
