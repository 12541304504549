import request from '../request'

/**
 * 空域
 */
export default class Airspace {
    /**
     * 列表
     * @param {*} params
     * @returns
     */
    static List(params) {
        return request.get('/hawksystemserver/airspace/web_selectLikeList', {
            params: params,
        })
    }

    /**
     * 限高区列表
     * @param {*} params
     * @returns
     */
    static GetLimitHeightAreas(params) {
        return request.get('/hawksystemserver/flight/getLimitHeightAreas', {
            params: params,
        })
    }
    /**
     * 禁飞区列表
     * @param {*} params
     * @returns
     */
    static GetNotFlyAreas(params) {
        return request.get('/hawksystemserver/flight/getNotFlyAreas', {
            params: params,
        })
    }
    /**
     * 电子围栏列表
     * @param {*} params
     * @returns
     */
    static GetAreaList(params) {
        return request.get('/hawksystemserver/flight/getAreaList', {
            params: params,
        })
    }
    /**
     * 电子围栏列表
     * @param {*} params
     * @returns
     */
    static GetCurrentAreaList(params) {
        return request.get('/hawksystemserver/flight/getCurrentAreaList', {
            params: params,
        })
    }

    /**
     * 查询航线名称数量
     * @param {*} params
     * @returns
     */
    static GetFlightLineCount(params) {
        return request.post('/hawksystemserver/flight/getFlightLineCount', params)
    }

    //空域类别
    static getTypeList(params) {
        return request.get(`hawksystemserver/airspacetype/getTypeList`)
    }
    // 空域类型


    
    static selectType(params) {
        return request.get(`/hawksystemserver/airspace/selectType`, {
            params: params,
        })
    }
    

    /**
     * 机构下拉列表
     * @param {*} params
     * @returns
     */
    static getAllOrganizationBack(params) {
        return request.get('/hawksystemserver/organization/getAllOrganizationBack', {
            params: params,
        })
    }
/**
     * 航线列表
     * @param {*} params
     * @returns
     */
 static addAirSpaceWeb(params) {
    return request.post('/hawksystemserver/airspace/addAirSpaceWeb', params)
}
    
  /**
   * 假日类型
   * @param {*} params
   * @returns
   */
   static getHolidayPatrolList(params) {
    return request({
      url: '/hawksystemserver/flightMission/getHolidayPatrolList',
      method: 'get',
      params,
    })
  }
}