import request from '../request'

/**
 * 
 * 数据墙
 */
export default class Monitor {
  /*
* @param {*} params
* @returns
*/
  static getList(params) {
    return request.get('/hawksystemserver/dataWall/getDataWallList', {
      params: params,
    })
  }
}