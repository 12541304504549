import NProgress from 'nprogress'
import 'nprogress/nprogress.css' //引入样式

import router from "./index"
import store from "../store"

router.beforeEach((to, from, next) => {
    NProgress.start()

    
    if(!window.showNewVersion){
        window.showNewVersion = true;
        //默认显示新版
        if (to.path === "/home"){
            next('/home');
        }
    }

    if (!_.isEmpty(store.getters.user_info)) {
        if (to.path === "/login") {
            next("/")
        } else {
            next()
        }
    } else {
        if (to.path !== "/login") {
            next("/login")
        } else {
            next()
        }
    }

})

router.beforeResolve((to, from, next) => {
    next()
})

router.afterEach((to, from) => {
    NProgress.done()
})