import request from '../request'


export default class Airspace {
    /**
     * aiUse页面/无人机树状图
     * @param {*} params
     * @returns
     */
    static uavList(params) {
        return request.post('/aiserver/face/shiju_aiFaceList', params)
    }

    /**
     * aiUse页面/人脸识别
     * @param {*} params
     * @returns
     */
    static LicensePlateRecognitionList(params) {
        return request.get('/aiserver/face/shiju_aiFaceList', {
            params: params
        })
    }

    /**
     * aiUse页面/车牌识别
     * @param {*} params
     * @returns
     */
    static aiPlateList(params) {
        return request.get('/aiserver/plate/shiju_aiPlateList', {
            params: params
        })
    }

    /**
     * 画框截屏
     * @param {*} data
     * @returns
     */
    static aiphoto(data) {
        return request.post('/aiserver/face/insertFaceResult', data)
    }
    static getFaceuavvideoMsg(params) {
        return request.get("/hawksystemserver/process/getFaceListByDeviceId", {
          params,
        });
      }

    /**
     *人脸截屏对比
     * @param {*} params
     * @returns
     */
    static aifaceId(params) {
        return request.get('/aiserver/face/queryFaceInfo', {
            params: params
        })
    }

    /**
     *车辆截屏对比
     * @param {*} params
     * @returns
     */
    static palteId(params) {
        return request.get('/aiserver/plate/getPlateInfoByImage', {
            params: params
        })
    }

}