import request from '../request'

export default class Home {

  /**
   * 红黑榜
   * @param {*} params
   * @returns
   */
  static getCardRankTop(params) {
    return request.get('/hawksystemserver/person-management/getCardRankTop', {
      params,
    })
  }
  /**
   * 无人机列表
   * @param {*} params
   * @returns
   */
  static ListDrone(params) {
    return request.get('/hawksystemserver/home/uavList', {
      params,
    })
  }

  /**
   * 无人机架次
   * @param {*} params
   * @returns
   */
  static GetJiaCi(params) {
    return request.get('/hawksystemserver/home/getJiaCi', {
      params,
    })
  }

  /**
   * 鹰巢列表
   * @param {*} params
   * @returns
   */
  static ListNest(params) {
    return request.get('/hawksystemserver/home/nestListWeb', {
      params,
    })
  }

  /**
   * 基站列表
   * @param {*} params
   * @returns
   */
  static ListBaseStation(params) {
    return request.get('/hawksystemserver/home/baseList', {
      params,
    })
  }

  /**
   * 停机坪列表
   * @param {*} params
   * @returns
   */
  static ListApron(params) {
    return request.get('/hawksystemserver/home/landList', {
      params,
    })
  }

  /**
   * 无人机轨迹
   * @param {*} params
   * @returns
   */
  static GetUavTrack(params) {
    return request.get('/hawksystemserver/home/getUavTrack', {
      params,
    })
  }
  /**
   * 飞行任务情况
   * @param {*} params
   * @returns
   */
  static FlightTask(params) {
    return request.get('/hawksystemserver/home/getFlightTask', {
      params,
    })
  }

  /**
   * 人员在位情况
   * @param {*} params
   * @returns
   */
  static PeopleInfo(params) {
    return request.get('/hawksystemserver/home/getCardInfo', {
      params,
    })
  }

  /**
   * 设备打卡情况
   * @param {*} params
   * @returns
   */
  static deviceInfo(params) {
    return request.get('/hawksystemserver/home/getDeviceCard', {
      params,
    })
  }


  /**
   * 数据统计
   * @param {*} params
   * @returns
   */
  static dataInfo(params) {
    return request.get('/hawksystemserver/home/deviceTotal2', {
      params,
    })
  }


  /**
   * 数据统计
   * @param {*} params
   * @returns
   */
   static dataInfos(params) {
    return request.get('/hawksystemserver/home/deviceTotalCount2', {
      params,
    })
  }

  /**
   * 无人机视频
   * @param {*} params
   * @returns
   */
  static getUavVideo(params) {
    return request.get('/hawksystemserver/home/deviceVideoes', {
      params,
    })
  }
  /**
   * 视频墙无人机视频
   * @param {*} params
   * @returns
   */
   static getDeviceStreamAddressList(params) {
    return request.get('/hawksystemserver/device/getDeviceStreamAddressList', {
      params,
    })
  }

  /**
   * 设备预警和消息预警
   * @param {*} params
   * @returns
   */
  static getwarningMsg(params) {
    return request.get('/hawksystemserver/home/getWaringInfoList', {
      params,
    })
  }

  /**
   * 人脸列表
   * @param {*} params
   * @returns
   */
  static getFaceMsg(params) {
    return request.get('/hawksystemserver/home/getWaringFaceList', {
      params,
    })
  }

  /**
   * 鹰击人脸列表
   * @param {*} params
   * @returns
   */
   static getFaceuavMsg(params) {
    return request.get('/hawksystemserver/process/getFaceListByUavId', {
      params,
    })
  }
/**
 * 鹰视人脸
 * @param {*} params 
 * @returns 
 */
  static getFaceuavvideoMsg(params) {
    return request.get("/hawksystemserver/process/getFaceListByUavId", {
      params,
    });
  }
  static getDeviceMsg(params) {
    return request.get('/hawksystemserver/home/onlineDevice', {
      params,
    })
  }
  /**
   * 鹰击
   * @param {*} params 
   * @returns 
   */
  static getflightMsg(params) {
    return request.get('/hawksystemserver/home/flightStatistics', {
      params,
    })
  }
  /**
   * 鹰视
   * @param {*} params 
   * @returns 
   */
  static getflightvideoMsg(params) {
    return request.get("/hawksystemserver/process/getPlateListByUavId", {
      params,
    });
  }

  /**
   * 创建人
   * @param {*} params 
   * @returns 
   */
   static getUsername(params) {
    return request.get('/hawksystemserver/user/getUserNameByOrgan', {
      params,
    })
  }



  /**
   * 人脸详情
   * @param {*} params
   * @returns
   */
  static getFaceDetails(params) {
    return request.get('/hawksystemserver/home/getWaringFaceDesc', {
      params,
    })
  }


  /**
   * 车辆列表
   * @param {*} params
   * @returns
   */
  static getCarMsg(params) {
    return request.get('/hawksystemserver/home/plateInfoList', {
      params,
    })
  }

  /**
   * 鹰击车辆列表
   * @param {*} params
   * @returns
   */
   static getCaruavMsg(params) {
    return request.get('/hawksystemserver/process/getPlateListByUavId', {
      params,
    })
  }

  /**
   * 绩效排名
   * @param {*} params
   * @returns
   */
  static getRank(params) {
    return request.get('/hawksystemserver/home/getTaskRankingByKey', {
      params,
    })
  }
 /**
   *
   * @param {*} params
   * @returns
   */
  static getDeviceTotalMsg(params) {
    return request.get('/hawksystemserver/home/deviceTotal', {
      params,
    })
  }
 

  

  /**
   * 无人机 鹰巢 基站 飞手列表接口
   * @param {*} params
   * @returns
   */
  static getonlineList(params) {
    return request.get('/hawksystemserver/home/deviceTotal2', {
      params,
    })
  }
  /**
   * 累计飞行次数(次)
   * @param {*} params
   * @returns
   */
  static getFlightdetail(params) {
    return request.get('/hawksystemserver/home/getAllFlightNumDesc', {
      params,
    })
  }
  /**
   * 累计任务数量详情接口
   * @param {*} params
   * @returns
   */
  static getAllAlarmNum(params) {
    return request.get('/hawksystemserver/home/getAllAlarmNumDesc', {
      params,
    })
  }

  /**
   * 在线记录接口
   * @param {*} params
   * @returns
   */
  static getonlineRecord(params) {
    console.log('id',params);
    return request.get(`/hawksystemserver/UavLog/getUavLogInfoWeb`,{params})
  }


  /**
   * 一键通知
   * @param {*} params
   * @returns
   */
  static keyNotification(data) {
    return request.post(
      '/hawksystemserver/person-management/oneNotification',
      data
    )
  }
    /**
   * 视频流检测
   * @param {*} params
   * @returns
   */
     static getStatisticsPushRecord(params) {
      return request.get(
        '/hawksystemserver/pushrecord/getStatisticsPushRecord', {
          params
        }
      )
    }


    
    /**
   * 无人机名称接口
   * @param {*} params
   * @returns
   */
     static getAllDeviceInfo(params) {
      return request.get(
        '/hawksystemserver/pushrecord/getAllDeviceInfo', {
          params
        }
      )
    }

  
  /**
   * 签到信息
   * @param {*} params
   * @returns
   */
  static signinInfo(params) {
    return request.get(
      '/hawksystemserver/home/getSignInfo', {
        params
      }
    )
  }
  /**
   * 获取值班表信息addRoster
   * @param {*} params
   * @returns
   */
  static getRoster(params) {
    console.log(params);
    return request.get(
      `/hawksystemserver/roster/getDutyDataPage`, {
        params
      }
    )
  }
  /**
   * 获取值班表人员
   * @param {*} params
   * @returns
   */
  static getRosterUser(params) {
    return request.get(
      `/hawksystemserver/user/selectIndexLikeList`,{params}
    )
  }
  /**
   * 添加值班表信息
   * @param {*} params
   * @returnsaddRosteraddRoster
   */

  static addRoster(params) {
    return request.post(
      '/hawksystemserver/roster/saveDutyDatas', params
    )
  }
  /**
   * 导入值班表信息
   * @param {*} params
   * @returnsaddRosteraddRoster
   */

  static importRoster(params) {
    return request.post(
      '/hawksystemserver/roster/importDutyData', params
    )
  }
  /**
   * 值班表模板下载
   * @param {*} params
   * @returnsaddRosteraddRoster
   */

  static exportTemplate() {
    return request.get(
      'hawksystemserver/roster/exportTemplate' 
    )
  }
  /**
   * 修改值班表信息
   * @param {*} params
   * @returnsaddRosteraddRoster
   */

  static editRoster(params) {
    return request.post(
      '/hawksystemserver/roster/updateDutyDataById', params
    )
  }
 /**飞行排名
  * 根据id查找机构任务
  * @param {*} params
  * @returnsaddRosteraddRoster
  */

 static rankDetail(params) {
   return request.get(
     '/hawksystemserver/home/getTaskRankingInfoById', {
       params
     }
   )
 }
  // /**
  //  * 无人机导出
  //  * @param {*} params
  //  * @returns
  //  */
  //  static exportfile() {
  //   return request.get(
  //     '/hawksystemserver/homeExcelReport/exportUavData'
  //   )
  // }

  /**
   * 在执无人机列表
   * @param {*} params
   * @returns
   */
  static devicesByTasking(params) {
    return request.get('/hawksystemserver/task2uav/devicesByTasking', {
      params,
    })
  }

  /**
   * 零登录统计
   * @param {*} params
   * @returns
   */
   static LoginStatistics(params) {
    return request.get('/hawksystemserver/home/logObjList', {
      params,
    })
  }

  /**
   * 人员零登录统计弹窗详情
   * @param {*} params
   * @returns
   */
   static LoginUserPopup(params) {
    return request.get('/hawksystemserver/home/logUserList', {
      params,
    })
  }

  /**
   * 单位零登录统计弹窗详情
   * @param {*} params
   * @returns
   */
   static LoginOrganPopup(params) {
    return request.get('/hawksystemserver/home/logDeviceList', {
      params,
    })
  }

  /**
   * 无人机零位移统计
   * @param {*} params
   * @returns
   */
   static DeviceMoveList(params) {
    return request.get('/hawksystemserver/home/deviceMoveList', {
      params,
    })
  }

  /**
   * 无人机零位移统计弹窗
   * @param {*} params
   * @returns
   */
   static DeviceMoveListPopup(params) {
    return request.get('/hawksystemserver/home/deviceMoveInfoList', {
      params,
    })
  }

  /**
   * 任務總計
   * @param {*} params
   * @returns
   */
   static taskStatisticsMsg(params) {
    return request.get('/hawksystemserver/home/taskStatistics', {
      params,
    })
  }
    /**
   * 数据状态检测
   * @param {*} params
   * @returns
   */
     static getOnlineDeviceCoun(params) {
      return request.get('/hawksystemserver/deviceManagement/getOnlineDeviceCount', {
        params,
      })
    }

    /**
   * 在执设备统计
   * @param {*} params
   * @returns
   */
     static GetStatisticsDevice(params) {
      return request.get('/hawksystemserver/deviceManagement/statisticsDeviceByDept', {
        params,
      })
    }

    /**
   * 在执设备统计弹窗
   * @param {*} params
   * @returns
   */
     static GetStatisticsDevicePopup(params) {
      return request.get('/hawksystemserver/deviceManagement/getOnlineRecordList', {
        params,
      })
    }
  


  /**
   * 获取滚动信息
   * @param {*} params
   * @returns
   */
   static rollingmessage() {
    return request.get('/hawksystemserver/webNews/getNewsInfo')
  }

 /**
   * 关闭滚动信息
   * @param {*} params
   * @returns
   */
  static readingmessage(params) {
    return request.post('/hawksystemserver/webNews/insertReadStatus', params)
  }

  /**
   * 关闭滚动预警信息
   * @param {*} params
   * @returns
   */
   static readingyjmessage(params) {
    return request.put('/hawksystemserver/device_waring/update',params)
  }


  /**
   * 获取勤务等级
   * @param {*} params
   * @returns
   */
  static getPoliceWorkLevel() {
    return request.get('/hawksystemserver/home/getPoliceWorkLevel')
  }
  /**
   * 修改勤务等级
   * @param {*} params
   * @returns
   */
  static setPoliceWorkLevel(params) {
    return request.post('/hawksystemserver/home/editPoliceWorkLevel', params)
  }
  /**
   * 安全检查
   * @param {*} params
   * @returns
   */
  static save_SecurityCheck(params) {
    return request.post('/hawksystemserver/uavTakeoffChecklist/insertUav', params)
  }
  /**
   * 查看是否已经设备报备
   * @param {*} params
   * @returns
   */
   static getCardStatus(params) {
    return request.get('/hawksystemserver/resource-report/getCardStatus')
  }
  /**
   * 车牌详情
   * @param {*} params
   * @returns
   */
   static getWaringPlateInfoById(params) {
    return request.get(`/hawksystemserver/home/getWaringPlateInfoById/${params.id}`)
  }

  // 
    /**
   * 记录操作日志
   * @param {*} params
   * @returns
   */
     static uavOperationLog(params) {
      return request.get('/hawksystemserver/log/uavOperationLog', {
        params,
      })
    }


      /**
   * 无人机起飞检查表记录新增接口
   * @param {*} params
   * @returns
   */
  static insertUav(params) {
    return request.post('/hawksystemserver/uavTakeoffChecklist/insertUav', params)
  }
  

      /**
   * 消息数量
   * @param {*} params
   * @returns
   */
       static getmessagenumber() {
        return request.get('/hawksystemserver/webNews/getNewsInfoCount')
      }

          /**
   * 适合起飞
   * @param {*} params
   * @returns
   */
           static istakeoff() {
            return request.get('/hawksystemserver/takeOff/getTakeOffByNow')
          }

       /**
   * 提示内容
   * @param {*} params
   * @returns
   */
   static getmessagecontent(params) {
    if(!params.newsId && !params.type){
      return request.get(`/hawksystemserver/webNews/getNewsList/${params.isRead}`)
    }else if(params.type){
      return request.get(`/hawksystemserver/webNews/getNewsList/${params.isRead}?type=${params.type}`)
    }else if(!params.type && params.newsId){
      return request.get(`/hawksystemserver/webNews/getNewsList/${params.isRead}?newsId=${params.newsId}`)
    }else{
      return request.get(`/hawksystemserver/webNews/getNewsList/${params.isRead}?newsId=${params.newsId}&type=${params.type}`)
    }
  }
  /**
   * 提示内容
   * @param {*} params
   * @returns
   */
   static getmessagetype() {
    return request.get(`/hawksystemserver/dictionaryInfo/dictionaryInfoList`)
  }

  static addnews(params) {
    return request.post('/hawksystemserver/news/addNews', params)
  }

  static exportUav(params) {
    return request.get(`/hawksystemserver/homeExcelReport/exportFlightCheck`,params)
  }
  /**
   * 设备飞行记录
   * @param {*} params 
   * @returns 
   */
  static flyrecord(params) {
    return request.get(`/hawksystemserver/UavLog/getUavLogWeb`,{params: params})
  }
  /**
   * 飞行检查记录
   * @param {*} params 
   * @returns 
   */
   static flycheck(params) {
    return request.get(`/hawksystemserver/uavTakeoffChecklist/getUavTakeoffCheckList`,{params: params})
  }
   /**
   * 报表统计
   * @param {*} params 
   * @returns 
   */
    static Statis(params) {
      return request.get(`/hawksystemserver/homeExcelReport/getUavOperationDataByWeek`,{params: params})
    }
     /**
   * 问卷调查
   * @param {*} params 
   * @returns 
   */
      static getSurvey(params) {
        return request.get(`/hawksystemserver/survey/getSurvey`,{params: params})
  }
   /**
   * 提交调查问卷
   * @param {*} params
   * @returns
   */
   static writeSurvey(data) {
    return request.post(
      '/hawksystemserver/survey/writeSurvey',
      data
    )
  }
}