//飞控中心相关
export default {
  namespaced: true,

  state: {
    selectMount: null, //选择人挂载
    nestCollapse: false, //鹰视左侧收起
    taskId: null, //选择的任务id
    lineInfo: {}, //航线信息
    YJtaskId: null, //选择的任务id（鹰击）
    obstacle: null, //避障信息 { deviceHardId: string(设备id), distances: array<int>（每10度一个与障碍物的距离厘米）; enable: boolean（是否在避障中）; obsDistance:int(避障警告距离米)}
    yc_video_status: '专网', //鹰巢视频使用公网链接还是专网链接
    stream: "QingLiu", //当前视频流类型
    channel7: null,
    drone_battery_maintenance_info: null, //大疆机库返回的电池数据
    active_nestData: null,//选中的鹰巢数据
    nest_nx_status: false,//鹰巢nx状态 fasle 代表nx异常
    nest_task_refresh: {},//鹰巢任务监听刷新列表与航线
    nest_task_srtatus: null,//鹰巢任务监听刷新列表与航线
    nset_fk_location: null,//鹰巢一键任务触发无人机定位 
    mount: null,
    ShowCenter: false,//瞄准镜开关
    uav_task_status: null,//结束任务时触发
  },

  mutations: {
    set_state(state, data) {
      state[data.key] = data.value;
    },
    SET_CHANNEL7(state, data) {
      state.channel7 = data;
    },
    SET_STREAM(state, data) {
      state.stream = data;
    },
    SET_SELECT_MOUNT(state, data) {
      state.selectMount = data;
    },
    SET_NEST_COLLAPSE(state, data) {
      state.nestCollapse = data;
    },
    SET_TASK_ID(state, data) {
      if (data != "z1322") {
        state.taskId = data;
      }
    },
    SET_TASK_INFO(state, data) {
      // if(data != "z1322"){
      state.lineInfo = data;
      // }
    },
    SET_TASK_ID_YJ(state, data) {
      if (data != "z1322") {
        state.YJtaskId = data;
      }
    },
    SET_ShowCenter(state, data) {
      state.ShowCenter = data;
    },
    SET_OBSTACLE(state, data) {
      state.obstacle = data;
    },
    SET_YC_VIDEO_STATUS(state, data) {
      state.yc_video_status = data;
    }
  },


  actions: {},
}