import {
  Message
} from 'element-ui';
let messageInstance = [];

const resetMessage = (options) => {
  const mLength = messageInstance.length
  if (mLength > 1) {
    messageInstance.forEach((item, index) => {
      if (index < mLength - 1) {
        item.close()
      }
    })
    messageInstance.splice(0, mLength - 1)
    // messageInstance[0].close()
  }
  messageInstance.push(Message(options))
};
['error', 'success', 'info', 'warning'].forEach(type => {
  resetMessage[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    options.type = type
    return resetMessage(options)
  }
})
export const message = resetMessage