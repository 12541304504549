import axios from "axios"
import { identity } from "lodash"
import request from "../request"

class Securityfly {

    static Security(id) {
        return request.get(`/hawksystemserver/lightInfo/getInfoList/${id}`)
    }

}

export default Securityfly