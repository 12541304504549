// export default {

//     /** 用户信息 */
//     user_info: state => state.user.USER_INFO,

// }

const modulesFiles = require.context('./modules', true, /\.js$/)
let states = {}
const modules = modulesFiles.keys().reduce((module, modulePath) => {
  //间后缀去掉
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  //获取模块返回的数据
  const value = modulesFiles(modulePath)
  for (let k in value.default.state) {
    //将state的数据进行遍历
    states[k] = (state) => state[moduleName][k]
  }
  return states
}, {})
const getters = modules
export default getters
