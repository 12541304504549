let mixin = {
  data() {
    return {
      judgepath: false
    }
  },
  created() {
    this.showpath()
  },
  methods: {
    //判断路由
      showpath(){
         try {
          if(this.$route.path.indexOf('/home')!='-1'||this.$route.path.indexOf('/observe/home?page=1')!='-1'){
            this.judgepath=true
          }
         } catch (error) {
           
         }
      }
    }
  }

export default mixin