import Lockr from 'lockr'
import axios from 'axios'
import qs from "qs"

import store from "../store"
import router from "../router"

const { VUE_APP_BASE_URL } = process.env

const request = axios.create({
    baseURL: VUE_APP_BASE_URL,
    timeout: 100000
})

const interceptors_request = [config => {

    if (config.qs == true) {
        config.url = config.url + '?' + qs.stringify(config.params, { indices: false })
        config.params = null
    }

    if (Lockr.get("FLYINGSESSIONID")) {
        config.headers.FLYINGSESSIONID = Lockr.get("FLYINGSESSIONID")
    }

    if (Lockr.get("IDENTITY")) {
        config.headers["mmc-identity"] = Lockr.get("IDENTITY")
    }

    return config
}, error => Promise.reject(error)]

request.interceptors.request.use(...interceptors_request)

const interceptors_response = [response => {

    let { status } = response.data
    
    if (status == 621) {
        // 用户登录已过期
        store.dispatch("user/Logout")
        router.go(0)
    } else {
        return response.data
    }

}, error => Promise.reject(error)]

request.interceptors.response.use(...interceptors_response)

export default request